import { useCallback, useMemo } from 'react';

import { UserType } from 'redux/apis/OG/user';

import { useModals, useNavigate } from '@zeel-dev/zeel-ui';

import { useUser } from '../index';
import Modal, { ModalSignInUpProps } from './modal';

// Login Trigger

// Login Trigger
export type TriggerLoginArgsType = { successRedirectUrl?: string } & ModalSignInUpProps;
export type TriggerLoginResponseType =
  | { success: true; error?: never; member: UserType; new?: boolean }
  | {
      success: false;
      error: string;
      member: null;
    };

// Hook
export type LoginHookArgsType = {};
export type LoginHookReturnType = {
  login: (args?: TriggerLoginArgsType) => Promise<TriggerLoginResponseType>;
};

const useLogin: (c?: LoginHookArgsType) => LoginHookReturnType = (/*hookArgs*/) => {
  const { openModal } = useModals();
  const { user, isAuthenticated, isFullMember, isLoading: isUserLoading } = useUser();
  const navigate = useNavigate();

  /**
   * Triggers login mechanism
   */
  const triggerLogin = useCallback<LoginHookReturnType['login']>(
    ({ successRedirectUrl, ...modalProps }) => {
      if (isUserLoading) return; // TODO: need to handle it better by queuing it instead
      return new Promise((resolve) => {
        if (isAuthenticated && isFullMember) {
          if (successRedirectUrl) return navigate(successRedirectUrl);
          else
            return resolve({
              success: true,
              member: user,
              new: false,
            });
        }
        try {
          openModal(
            {
              id: modalProps?.id || 'modal-login',
              element: <Modal {...modalProps} />,
            },
            (response) => {
              if (response) {
                if (successRedirectUrl) return navigate(successRedirectUrl);
                else
                  return resolve({
                    success: true,
                    member: response.member,
                    new: response.new,
                  });
              } else {
                return resolve({
                  success: false,
                  error: 'Canceled login',
                  member: null,
                });
              }
            }
          );
        } catch (modalError: any) {
          console.error(modalError);
          return resolve({
            success: false,
            member: null,
            error: JSON.stringify(modalError),
          });
        }
      });
    },
    [openModal, user, isAuthenticated, isFullMember, isUserLoading]
  );

  return useMemo(() => ({ login: triggerLogin }), [triggerLogin]);
};

export default useLogin;
