import { registerMapping } from '@zeel-dev/zeel-ui';

import * as T from './types';

export const PaymentMethodMapping = registerMapping<T.SourcePaymentMethodType, T.PaymentMethodType>('PaymentMethod', {
  operations: [
    ['toString', { sourceProperty: 'id' }],
    [
      'copy',
      {
        sourceProperties: ['token', 'expired', 'status'],
      },
    ],
    ['copy', { sourceProperty: 'braintree_payment_method_token', targetProperty: 'braintreePaymentMethodToken' }],
    ['copy', { sourceProperty: 'braintree_customer_profile_id', targetProperty: 'braintreeCustomProfileId' }],
    ['copy', { sourceProperty: 'last_four', targetProperty: 'lastFourDigits' }],
    ['copy', { sourceProperty: 'expiration_year', targetProperty: 'expirationYear' }],
    ['copy', { sourceProperty: 'expiration_month', targetProperty: 'expirationMonth' }],
    ['toString', { sourceProperty: 'postal_code', targetProperty: 'zipCode' }],
    ['copy', { sourceProperty: 'card_bin', targetProperty: 'cardBin' }],
    ['copy', { sourceProperty: 'profile_name', targetProperty: 'label' }],
    ['copy', { sourceProperty: 'fname', targetProperty: 'firstName' }],
    ['copy', { sourceProperty: 'lname', targetProperty: 'lastName' }],
    ['copy', { sourceProperty: 'card_type', targetProperty: 'cardType' }],
    ['copy', { sourceProperty: 'card_type_abbreviation', targetProperty: 'cardTypeAbbreviation' }],
    ['copy', { sourceProperty: 'dt_inserted', targetProperty: 'dateCreated' }],
    ['copy', { sourceProperty: 'dt_edited', targetProperty: 'dateEdited' }],
    ['copy', { sourceProperty: 'can_update', targetProperty: 'updatable' }],
    ['copy', { sourceProperty: 'external_type', targetProperty: 'processor' }],
    ['boolLiteralToBool', { sourceProperty: 'default' }],
  ],
  decoders: {
    source: T.SourcePaymentMethodDecoder,
    target: T.PaymentMethodDecoder,
  },
  derivedProperties: [
    ['statusLabel', 'labelFull', 'expirationLabel'],
    ({ input }) => ({
      statusLabel: input.expired ? 'expired' : input.status ? 'good' : 'invalid',
      expirationLabel: input.expiration_month + '/' + input.expiration_year?.substr(2),
      labelFull:
        input.profile_name ||
        `${input.card_type} ending in ${input.last_four}
        ${input.status_label === 'expired' ? ' - Expired' : ''}
        ${input.status_label === 'invalid' ? ' - Invalid' : ''}`,
    }),
  ],
});

export const PaymentOptionDetailsMapping = registerMapping<
  T.SourcePaymentOptionDetailsType,
  T.PaymentOptionDetailsType
>('PaymentOptionDetails', {
  operations: [
    ['boolLiteralToBool', { sourceProperty: 'available' }],
    ['boolLiteralToBool', { sourceProperty: 'webview_available', targetProperty: 'webviewAvailable' }],
    ['copy', { sourceProperty: 'include_vaulted', targetProperty: 'includeVaulted' }],
    ['copy', { sourceProperty: 'person_id', targetProperty: 'personId' }],
    ['copy', { sourceProperty: 'recommended_collection_type', targetProperty: 'recommendedCollectionType' }],
    ['copy', { sourceProperty: 'processors' }],
    ['compose', { sourceProperty: 'vaulted', mapper: 'PaymentMethod' }],
    ['copy', { sourceProperty: 'vault_with_sale', targetProperty: 'vaultWithSale' }],
  ],
  decoders: {
    source: T.SourcePaymentOptionDetails,
    target: T.PaymentOptionDetailsDecoder,
  },
});
