import { useCallback, useEffect, useState } from 'react';

/**
 * Hook that dynamically injects a script at the end of the html head. And on unmount, it removes it
 */
export const useZoomInfo = (params?: {
  disabled?: boolean;
  onMatch?: (matchData: any) => void | Promise<void>;
}): {
  ready: boolean;
  matching: boolean;
  match: any;
} => {
  const [zoomInfoReady, setZoomInfoReady] = useState(false);
  const [zoomInfoRetrieving, setZoomInfoRetrieving] = useState(false);
  const [zoomInfoMatchData, setZoomInfoMatchData] = useState(null);

  useEffect(() => {
    if (params?.disabled) return;
    const script = document.createElement('script');
    script.id = 'zoom-info-script';
    script.innerHTML = `window[(function(_BR7,_eI){var _Pv='';for(var _Hm=0;_Hm<_BR7.length;_Hm++){_GJ!=_Hm;_Pv==_Pv;var _GJ=_BR7[_Hm].charCodeAt();_GJ-=_eI;_GJ+=61;_GJ%=94;_eI>7;_GJ+=33;_Pv+=String.fromCharCode(_GJ)}return _Pv})(atob('c2JpLSolfnwvZH40'), 25)] = 'fe6424308f1685113051';     var zi = document.createElement('script');     (zi.type = 'text/javascript'),     (zi.async = true),     (zi.src = (function(_Mcl,_4v){var _Ad='';for(var _In=0;_In<_Mcl.length;_In++){var _yj=_Mcl[_In].charCodeAt();_yj-=_4v;_Ad==_Ad;_yj+=61;_yj%=94;_4v>8;_yj!=_In;_yj+=33;_Ad+=String.fromCharCode(_yj)}return _Ad})(atob('eCYmIiVKPz96JT4seT0lcyR5IiYlPnMhfT8seT0mcXc+eiU='), 16)),     document.readyState === 'complete'?document.body.appendChild(zi):     window.addEventListener('load', function(){         document.body.appendChild(zi)     });`;
    document.head.appendChild(script);
    return () => {
      const script = document.getElementById('zoom-info-script');
      if (script) script.remove();
    };
  }, []);

  const handleOnZoomInfoRequestSent = useCallback(() => {
    setZoomInfoRetrieving(true);
  }, []);

  const handleOnZoomInfoMatch = useCallback(
    (matchData) => {
      setZoomInfoRetrieving(false);
      setZoomInfoMatchData(matchData);
      if (params?.onMatch) params.onMatch(matchData);
    },
    [params]
  );

  useEffect(() => {
    if (params?.disabled) return;
    const interval = setInterval(() => {
      if ((window as any)._zi_fc) {
        (window as any)._zi_fc.onRequestSent = handleOnZoomInfoRequestSent;
        (window as any)._zi_fc.onMatch = handleOnZoomInfoMatch;
        setZoomInfoReady(true);
        clearInterval(interval);
      }
    }, 1000);
    return () => {
      if (interval) clearInterval(interval);
    };
  }, []);

  return {
    ready: zoomInfoReady,
    matching: zoomInfoRetrieving,
    match: zoomInfoMatchData,
  };
};
