import { FC, useCallback, useEffect, useMemo, useState } from 'react';

import { useForm } from 'utils/hooks';

import { useSignInMutation, useSignUpMutation } from 'redux/apis/OG/auth';

import { FieldRow, Input, Link } from 'components/common';
import Modal, { ModalProps } from 'components/modals/templates/ModalNew';

import { useSendEmailFunnelMutation } from '../../../redux/apis/OG/booking';
import styles from './style.module.scss';

type CommonModalConfig = Pick<ModalProps, 'title' | 'description'> & {
  buttonLabel?: ModalProps['actions'][0]['label'];
};

type Mode = 'signIn' | 'signUp';
export type ModalSignInUpProps = ModalProps & {
  initialEmail?: string;
  initialMode?: Mode;
  signIn?: CommonModalConfig;
  signUp?: CommonModalConfig;
};

const ModalSignInUp: FC<ModalSignInUpProps> = ({
  initialEmail,
  initialMode,
  onClose,
  signIn: signInModalConfig,
  signUp: signUpModalConfig,
  ...rest
}) => {
  const form = useForm();
  const [checkEmailUsage] = useSendEmailFunnelMutation();
  const [signIn] = useSignInMutation();
  const [signUp] = useSignUpMutation();

  const [mode, setMode] = useState<Mode>(initialMode || 'signIn');
  const [overrideModalConfig, setOverrideModalConfig] = useState<CommonModalConfig | null>(null);
  const [passwordStrengthState, setPasswordStrengthState] = useState<boolean>(false);

  useEffect(() => {
    if (initialEmail) {
      form.setValue('email', initialEmail);
    }
  }, [initialEmail, form]);

  const changeMode = useCallback((newMode: Mode, _overrideModalConfig?: CommonModalConfig) => {
    setMode(newMode);
    setOverrideModalConfig(_overrideModalConfig || null);
    form.setGlobalStatus(null);
  }, []);

  const login = useCallback(async () => {
    const _email = form.getValue('email');
    const password = form.getValue('password');

    form.blur();
    try {
      const { id } = await signIn({ email: _email, password }).unwrap();
      onClose({
        member: { id },
        new: false,
      });
    } catch (errors: any) {
      if (errors?.authentication) {
        form.setGlobalStatus({ error: 'Email or password is incorrect' });
      }
    }
  }, [form]);

  const createAccount = useCallback(async () => {
    const { firstName, lastName, email, password } = form.getValues();
    // Check email
    try {
      await checkEmailUsage({ email }).unwrap(); // will always fail since we don't pass zip and category
    } catch (errors: any) {
      if (errors?.user_exists) {
        return changeMode('signIn', { title: 'Welcome Back!', description: 'Please log into your existing account' });
      }
    }
    // Create account
    try {
      const { id } = await signUp({ firstName, lastName, email, password, passwordRepeat: password }).unwrap();
      onClose({
        member: { id },
        new: true,
      });
    } catch (errors: any) {
      if (errors?.fname) form.setError('firstName', errors.fname);
      if (errors?.lname) form.setError('lastName', errors.lname);
      if (errors?.email) form.setError('email', errors.email);
      if (errors?.password) form.setError('password', errors.password);
    }
  }, [form, changeMode]);

  const getModalProps = () => {
    switch (mode) {
      case 'signIn':
        return {
          ...rest,
          title: signInModalConfig?.title || 'Sign In',
          description: signInModalConfig?.description || (
            <>
              Don&apos;t have an account?{' '}
              <Link styled onClick={() => setMode('signUp')}>
                Sign up!
              </Link>
            </>
          ),
          actions: [
            {
              label: signInModalConfig?.buttonLabel || 'Sign In',
              onClick: login,
              disabled: !form.isValid(['email', 'password']),
            },
          ],
          onSubmit: form.isValid(['email', 'password']) ? login : undefined,
          ...(overrideModalConfig || {}),
        };
      case 'signUp':
        return {
          ...rest,
          title: signUpModalConfig?.title || 'Create an account',
          description: signUpModalConfig?.description || (
            <>
              Already have an account?{' '}
              <Link styled onClick={() => setMode('signIn')}>
                Sign in!
              </Link>
            </>
          ),
          actions: [
            {
              label: signUpModalConfig?.buttonLabel || 'Create Account',
              onClick: createAccount,
              disabled: !form.isValid(['firstName', 'lastName', 'email', 'password']) || !passwordStrengthState,
            },
          ],
          onSubmit:
            form.isValid(['firstName', 'lastName', 'email', 'password']) && passwordStrengthState
              ? createAccount
              : undefined,
          ...(overrideModalConfig || {}),
        };
    }
  };

  const inputProps = useMemo(
    () => ({
      className: styles.input,
      wrapperClassName: styles.inputWrapper,
      hideValidationSpaceWhenEmpty: true,
    }),
    []
  );

  console.log({
    passwordStrengthState,
    values: form.getValues(),
  });

  return (
    <Modal
      {...getModalProps()}
      className={styles.base}
      headerClassName={styles.header}
      bodyClassName={styles.body}
      footerClassName={styles.footer}
      iconsBarClassName={styles.iconsBar}
      mobileType={'drawer'}
      testId='login-modal'
      onClose={() => onClose()}
      alert={form.globalStatus?.error}
      backdropClose={false}
    >
      {mode === 'signIn' && (
        <>
          <FieldRow>
            <Input
              {...form.getProps('email')}
              {...inputProps}
              type='email'
              placeholder='Email address'
              autoComplete='email username'
              testId='login-modal--email-input'
              hideValidation
            />
          </FieldRow>
          <FieldRow>
            <Input
              {...form.getProps('password')}
              {...inputProps}
              type='password'
              placeholder='Password'
              autoComplete='current-password'
              testId='login-modal--password-input'
              hideValidation
            />
          </FieldRow>
        </>
      )}
      {mode === 'signUp' && (
        <>
          <FieldRow>
            <Input
              {...form.getProps('firstName')}
              {...inputProps}
              placeholder='First Name'
              autoComplete='given-name'
              testId='login-modal--first-name-input'
              autoFocus
              hideValidation
            />
            <Input
              {...form.getProps('lastName')}
              {...inputProps}
              placeholder='Last Name'
              autoComplete='family-name'
              testId='login-modal--last-name-input'
              hideValidation
            />
          </FieldRow>
          <FieldRow>
            <Input
              {...form.getProps('email')}
              {...inputProps}
              type='email'
              placeholder='Email address'
              autoComplete='email username'
              testId='login-modal--email-input'
              hideValidation
            />
          </FieldRow>
          <FieldRow>
            <Input
              {...form.getProps('password')}
              {...inputProps}
              type='password'
              placeholder='Password'
              autoComplete='current-password'
              testId='login-modal--password-input'
              hideValidation
              passwordGuidelines={form.getValue('password') ? 'always' : true}
              passwordGuidelinesStateChange={(s) => setPasswordStrengthState(s)}
            />
          </FieldRow>
        </>
      )}
    </Modal>
  );
};

export default ModalSignInUp;
