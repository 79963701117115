import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import clsx from 'clsx';

import {
  PaymentMethodType,
  PaymentProcessor,
  PaymentUsedFor,
  getDefaultPaymentMethod,
  useCreateProcessorPaymentMethodMutation,
  useGetPaymentOptionsCCQuery,
  useLazyGetClientProcessorTokenQuery,
  usePassthroughPaymentFortisChargeApiMutation,
  usePassthroughPaymentSequenceMutation,
} from 'redux/apis/OG/payment';

import { useModals } from '@zeel-dev/zeel-ui';
import FieldTitle from 'components/common/FieldTitle';
import LinkButton from 'components/common/LinkButton';
import PaymentItem from 'components/common/PaymentItem';
import ModalNew, { ModalProps } from 'components/modals/templates/ModalNew';

import { useAuth, useUser } from '../';
import Icon from '../../../components/common/Icon';
import { formatCurrency, onLowerEnvironment } from '../../helpers';
import styles from './style.module.scss';

type HookConfigType<PT, RT> = {
  paymentUsedFor: PaymentUsedFor;
  apiPayload: PT;
  total: number;
  onChargeSuccess?: (apiResponse: RT) => void | Promise<void>;
  onChargeError?: (error: ChargeError) => void | Promise<void>;
};

type HookReturnType<PT, RT> = {
  ready: boolean;
  error: ChargeError | null;
  result: RT | null;
  submitting: boolean;
  charging: boolean;
  success: boolean;
  willCharge?: boolean;
  triggerSubmission?: TriggerSubmissionType;
};

type TriggerSubmissionType = () => void | Promise<void>;

type ChargeError = {
  error: string;
};

const FORTIS_ADD_MODAL_ID = 'payment-collect-fortis-add-modal';
const EDIT_MODAL_ID = 'payment-collect-edit-modal';
const LIST_MODAL_ID = 'payment-collect-list-modal';

const usePaymentWith3ds = <PT, RT>({
  paymentUsedFor,
  apiPayload,
  total,
  onChargeSuccess,
  onChargeError,
}: HookConfigType<PT, RT>): HookReturnType<PT, RT> => {
  const { isAuthenticated } = useAuth();
  const { openModal, closeModal } = useModals();

  const [submitting, setSubmitting] = useState<boolean>(false);
  const [charging, setCharging] = useState<boolean>(false);
  const [chargeError, setChargeError] = useState<ChargeError | null>(null);
  const [chargeResult, setChargeResult] = useState<RT | null>(null);

  const { data: paymentOptionDetails, isFetching: isPaymentOptionDetailsFetching } = useGetPaymentOptionsCCQuery(
    {
      usedFor: paymentUsedFor,
      bookingData: apiPayload,
    },
    { skip: !isAuthenticated }
  );
  const [passthroughPaymentSequenceApi] = usePassthroughPaymentSequenceMutation();
  const [passthroughPaymentFortisChargeApi] = usePassthroughPaymentFortisChargeApiMutation();

  const apiPath = useMemo(() => {
    return (
      {
        [PaymentUsedFor.Gift]: `/discountcode/create/gift_card`,
      }[paymentUsedFor] || '/'
    );
  }, [paymentUsedFor]);

  const willCharge = useMemo(() => paymentOptionDetails?.vaultWithSale || false, [paymentOptionDetails?.vaultWithSale]);

  /**  HANDLERS **/
  const charge = useCallback(
    async (args: { vaultedPaymentMethodId?: string; fortisClientToken?: string; fortisTransactionId?: string }) => {
      const { vaultedPaymentMethodId, fortisClientToken, fortisTransactionId } = args;
      setCharging(true);
      if (vaultedPaymentMethodId) {
        console.log('About to charge using the vaulted payment profile: ', vaultedPaymentMethodId);
        try {
          const response = await passthroughPaymentSequenceApi({
            apiPath,
            apiPayload: { ...(apiPayload || {}), payment_profile_id: vaultedPaymentMethodId },
          }).unwrap();
          console.log('Charge response:', response);
          const _chargeResult = response as RT;
          setChargeResult(_chargeResult);
          onChargeSuccess?.(_chargeResult);
        } catch (apiError: any) {
          console.log('Charge error: ', apiError);
          const _chargeError = { error: apiError?.message };
          setChargeError(_chargeError);
          onChargeError?.(_chargeError);
        }
      } else if (fortisClientToken && fortisTransactionId) {
        console.log('About to charge using one-time sale: ', fortisTransactionId, fortisClientToken);
        try {
          const response = await passthroughPaymentFortisChargeApi({
            clientToken: fortisClientToken,
            transactionId: fortisTransactionId,
            usedFor: paymentUsedFor,
          }).unwrap();
          console.log('Charge response:', response);
          const _chargeResult = response?.sale_response as RT;
          console.log('Charge result: ', _chargeResult);
          setChargeResult(_chargeResult);
          onChargeSuccess?.(_chargeResult);
        } catch (apiError: any) {
          console.log('Charge error: ', apiError);
          const _chargeError = { error: apiError?.message };
          setChargeError(_chargeError);
          onChargeError?.(_chargeError);
        }
      } else {
        setChargeError({ error: 'Invalid Payment Method' });
      }
      setCharging(false);
    },
    [passthroughPaymentSequenceApi, apiPath, apiPayload, onChargeSuccess, onChargeError, paymentUsedFor]
  );

  /**  SHOWS  **/
  const showFortisAddView = useCallback(() => {
    closeModal(LIST_MODAL_ID);
    closeModal(EDIT_MODAL_ID);
    const canGoBack = (paymentOptionDetails?.vaulted || []).length > 0;
    openModal(
      {
        id: FORTIS_ADD_MODAL_ID,
        element: (
          <FortisAddModal
            usedFor={paymentUsedFor}
            apiPayload={apiPayload}
            total={total}
            willCharge={willCharge}
            backClicked={canGoBack ? () => showListView() : undefined}
          />
        ),
      },
      async (args: { fortisClientToken?: string; fortisTransactionId?: string; vaultedPaymentMethodId?: string }) => {
        if (args?.fortisClientToken && args?.fortisTransactionId) {
          await charge({ fortisClientToken: args.fortisClientToken, fortisTransactionId: args.fortisTransactionId });
        } else if (args?.vaultedPaymentMethodId) {
          await charge({ vaultedPaymentMethodId: args.vaultedPaymentMethodId });
        }
        setSubmitting(false);
      }
    );
  }, [paymentOptionDetails, paymentUsedFor, apiPayload, willCharge, total]);

  const showEditView = useCallback(
    (cc: PaymentMethodType) => {
      closeModal(FORTIS_ADD_MODAL_ID);
      closeModal(LIST_MODAL_ID);
      // NOT SUPPORTED YET
    },
    [charge]
  );

  const showListView = useCallback(() => {
    closeModal(FORTIS_ADD_MODAL_ID);
    closeModal(EDIT_MODAL_ID);
    openModal(
      {
        id: LIST_MODAL_ID,
        element: (
          <ListModal
            willCharge={willCharge}
            editClicked={(cc) => showEditView(cc)}
            addClicked={() => showFortisAddView()}
            paymentMethods={paymentOptionDetails?.vaulted}
            total={total}
          />
        ),
      },
      async (_vaultedPaymentMethodId: string) => {
        if (_vaultedPaymentMethodId) {
          await charge({ vaultedPaymentMethodId: _vaultedPaymentMethodId });
        }
        setSubmitting(false);
        console.log('list modal callback');
      }
    );
  }, [charge, paymentOptionDetails, willCharge, total]);

  /**  TRIGGERS  **/
  const triggerSubmission = useCallback(async () => {
    setSubmitting(true);
    if ((paymentOptionDetails?.vaulted || []).length === 0) {
      showFortisAddView();
    } else {
      showListView();
    }
  }, [showFortisAddView, showListView, willCharge, charge]);

  return useMemo(() => {
    console.log('Payment Option Details: ', paymentOptionDetails);
    return {
      ready: !isPaymentOptionDetailsFetching,
      error: chargeError,
      result: chargeResult,
      submitting,
      charging,
      success: !submitting && !charging && !!chargeResult && !chargeError,
      triggerSubmission,
    };
  }, [
    isPaymentOptionDetailsFetching,
    chargeError,
    chargeResult,
    submitting,
    charging,
    chargeResult,
    paymentOptionDetails?.available,
    paymentOptionDetails?.webviewAvailable,
    paymentOptionDetails?.includeVaulted,
    paymentOptionDetails?.personId,
    paymentOptionDetails?.recommendedCollectionType,
    JSON.stringify(paymentOptionDetails?.processors || []),
    JSON.stringify(paymentOptionDetails?.vaulted || []),
    triggerSubmission,
  ]);
};

const DEVMODE_PAYMENT_INFO = {
  cardNumber: '5454545454545454',
  expiryDate: '12/2025',
  cvv: '999',
  label: 'Master card',
  zipCode: '10005',
};

/**
 * Modal that shows the Fortis SDK Iframe to create a payment method
 * and returns the clientToken and transactionId via the onClose callback.
 */
const FortisAddModal: FC<
  ModalProps & {
    willCharge?: boolean;
    usedFor: PaymentUsedFor;
    apiPayload: any;
    total: number;
    backClicked?: () => void;
  }
> = ({ willCharge, usedFor, apiPayload, total, onClose, backClicked, ...rest }) => {
  const user = useUser();
  const FORTIS_CONTAINER_NODE_ID = 'fortis-sdk-payment-form';
  const [clientToken, setClientToken] = useState<string | null>(null);

  // If the fortis sdk is ready to be rendered
  const [authorizedAndReady, setAuthorizedAndReady] = useState<boolean>();

  // States
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [mutationEventData, setMutationEventData] = useState<any>();
  const [mutating, setMutating] = useState<boolean>(false);
  const [, /*hasValidationErrors*/ setHasValidationErrors] = useState<boolean>(false);
  const [localError, setLocalError] = useState<string | null>(null);
  const elements = useRef<any>();

  const [getFortisClientTokenQuery] = useLazyGetClientProcessorTokenQuery();
  const [createProcessorPaymentMethodMutation] = useCreateProcessorPaymentMethodMutation();

  // Reference to the fortis elements instance

  useEffect(() => {
    const init = async () => {
      const devMode = onLowerEnvironment();
      if (devMode) console.log('DEVMODE_PAYMENT_INFO', DEVMODE_PAYMENT_INFO);

      try {
        const fortisClientToken = await getFortisClientTokenQuery({
          processor: PaymentProcessor.Fortis,
          usedFor,
          extraPayload: apiPayload,
          charge: willCharge,
        }).unwrap();
        setClientToken(fortisClientToken);
        elements.current = new (window as any).Commerce.elements(fortisClientToken);
        elements.current?.create({
          container: `#${FORTIS_CONTAINER_NODE_ID}`,
          theme: 'default',
          environment: devMode ? 'sandbox' : 'production',
          floatingLabels: false,
          showSubmitButton: false,
          showValidationAnimation: true,
          showReceipt: false,
          hideTotal: true,
          fields: {
            billing: [
              { name: 'postal_code', required: true },
              { name: 'country', value: 'US' },
            ],
            additional: [{ name: 'email_address', value: user?.user?.email, hidden: true }],
          },
          digitalWallets: [],
          appearance: {
            colorButtonSelectedBackground: '#363636',
            colorButtonSelectedText: '#ffffff',
            colorButtonActionBackground: '#00d1b2',
            colorButtonActionText: '#ffffff',
            colorButtonBackground: '#ffffff',
            colorButtonText: '#363636',
            colorFieldBackground: '#ffffff',
            colorFieldBorder: '#dbdbdb',
            colorText: '#4a4a4a',
            colorLink: '#485fc7',
            fontSize: '16px',
            marginSpacing: '-20px',
            borderRadius: '12px',
            rowMarginSpacing: '-20px',
          },
        });
      } catch (ctError: any) {
        onClose?.();
        return console.error(ctError);
      }
      registerCallbacks();
    };
    init();
  }, []);

  const onReady = useCallback(() => {
    setAuthorizedAndReady(true);
  }, []);

  const onSubmitted = useCallback(() => {
    setHasValidationErrors(false);
    setSubmitting(true);
  }, []);

  const onValidationError = useCallback(() => {
    setSubmitting(false);
    setHasValidationErrors(true);
  }, []);

  const onDone = useCallback(
    async (event?: any) => {
      if (mutating) return;
      setSubmitting(false);
      setMutationEventData(event);
    },
    [mutating]
  );

  const handleMutation = useCallback(async () => {
    if (mutating || !mutationEventData) return;
    setHasValidationErrors(false);
    setLocalError(null);
    if (willCharge) {
      const fortisTransactionId = mutationEventData?.data?.id;
      const fortisClientToken = clientToken;
      onClose?.({
        fortisClientToken,
        fortisTransactionId,
      });
    } else {
      try {
        const nonce = mutationEventData.data.nonce;
        const transactionId = mutationEventData.data.id;
        const paymentData = transactionId ? { transactionId } : { nonce };
        const response = await createProcessorPaymentMethodMutation({
          processor: PaymentProcessor.Fortis,
          usedFor,
          ...paymentData,
        }).unwrap();
        onClose?.({
          vaultedPaymentMethodId: response.id,
        });
      } catch (_e) {
        console.error(_e);
        onClose?.();
      }
    }
  }, [mutationEventData, clientToken]);

  useEffect(() => {
    if (submitting || mutating || !mutationEventData || localError) return;
    setMutating(true);
    handleMutation();
  }, [mutationEventData, submitting, mutating, handleMutation, localError]);

  const onTokenExpired = useCallback((errors: any) => {
    console.error(errors);
    onClose?.();
  }, []);

  const onError = useCallback(
    (errors: any) => {
      console.error(errors);
      onClose?.();
    },
    [onClose]
  );

  const registerCallbacks = useCallback(() => {
    if (!elements.current) return;
    elements.current.on('ready', onReady);
    elements.current.on('submitted', onSubmitted);
    elements.current.on('validationError', onValidationError);
    elements.current.on('done', onDone);
    elements.current.on('tokenExpired', onTokenExpired);
    elements.current.on('error', onError);
  }, [onReady, onSubmitted, onValidationError, onDone, onTokenExpired, onError]);

  useEffect(() => registerCallbacks(), [registerCallbacks]);

  return (
    <ModalNew
      {...rest}
      onBack={backClicked}
      mobileType={'drawer'}
      iconsBarClassName={styles['modal-iconsBar']}
      headerClassName={styles['modal-header--hidden']}
      bodyClassName={styles['modal-body']}
      footerClassName={clsx(styles['modal-footer'], styles['modal-footer--tight'])}
      className={clsx(styles.modal, { [styles['modal--fixedLoading']]: !authorizedAndReady })}
      onClose={() => onClose?.()}
      testId={FORTIS_ADD_MODAL_ID}
      actions={
        authorizedAndReady
          ? [
              {
                label: `Pay ${formatCurrency(total)}`,
                onClick: () => elements.current?.submit(),
                disabled: !authorizedAndReady || !!localError,
                loading: submitting || mutating,
              },
            ]
          : []
      }
    >
      {!authorizedAndReady && (
        <div className={styles['sdk-form-loader']}>
          <Icon type='fa-circle-o-notch fa-spin' className={styles.loader} />
        </div>
      )}
      <div id={FORTIS_CONTAINER_NODE_ID} />
    </ModalNew>
  );
};

const ListModal: FC<
  ModalProps & {
    willCharge?: boolean;
    paymentMethods: PaymentMethodType[];
    total: number;
    editClicked?: (cc: PaymentMethodType) => void;
    addClicked?: () => void;
  }
> = ({ onClose, paymentMethods = [], total, addClicked, ...rest }) => {
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<PaymentMethodType | null>(
    getDefaultPaymentMethod(paymentMethods || []) || null
  );

  return (
    <ModalNew
      {...rest}
      title={'Complete Payment'}
      mobileType={'drawer'}
      iconsBarClassName={styles['modal-iconsBar']}
      headerClassName={styles['modal-header']}
      bodyClassName={styles['modal-body']}
      footerClassName={styles['modal-footer']}
      className={styles.modal}
      onClose={() => onClose?.()}
      testId={LIST_MODAL_ID}
      actions={[
        {
          label: `Pay ${formatCurrency(total)}`,
          onClick: () => onClose?.(selectedPaymentMethod?.id),
          disabled: !selectedPaymentMethod,
        },
      ]}
    >
      <FieldTitle className={styles.topper}>Credit Cards</FieldTitle>
      {(paymentMethods || []).map((creditCard, index) => {
        // const updatable = creditCard.updatable && creditCard.processor;
        return (
          <PaymentItem
            key={creditCard.id}
            selected={selectedPaymentMethod?.id === creditCard.id}
            payment={creditCard}
            onClick={() => setSelectedPaymentMethod(creditCard)}
            // onEdit={updatable ? () => editClicked?.(creditCard) : undefined}
            testId={`PMC-list--payment-item-${index}`}
          />
        );
      })}
      <LinkButton
        className={styles.link}
        arrow={false}
        icon='add-circle-bold'
        iconProps={{ size: 22 }}
        onClick={() => addClicked?.()}
        testId='PMC-list--add-new-payment-method-link'
      >
        {'Use Another Card'}
      </LinkButton>
    </ModalNew>
  );
};

export default usePaymentWith3ds;
