import { FC, ReactNode, useCallback, useState } from 'react';

import clsx from 'clsx';
import isNil from 'lodash/isNil';
import moment from 'moment';

import { helpers, log, routes } from 'utils';
import { useForm } from 'utils/hooks';

import {
  Button,
  Checkbox,
  Chips,
  FieldRow,
  IconSvg,
  Input,
  InputDate,
  InputLocation,
  Link,
  Notification,
  Segments,
  Select,
} from 'components/common';

import { useZoomInfo } from '../../../utils/hooks/useZoomInfo';
import styles from './style.module.scss';

type Props = {
  id?: string;
  className?: string;
  stepClassName?: string;
  title?: boolean | string | ReactNode;
  successTitle?: boolean | string | ReactNode;
  description?: boolean | string | ReactNode;
  descriptionClassName?: string;
  successDescription?: boolean | string | ReactNode;
  steps?: Array<StepType>;
  mainButtonText?: string;
  submitButtonText?: string;
  additionalSalesforceFields?: { [key: string]: any }; // if you want to pass any additional salesforce fields, these will override any other fields submitted
  submitDelay?: number;
  finePrint?: any;
  showTerms?: boolean;
  customTerms?: any;
  fixedHeight?: boolean; // default false - if steps with varying height will make the form jump, or if they will all be the size of the tallest step
  topAnchor?: string;
  customSubmitHandler?: (fields: { [key: string]: any }, sfFields: { [key: string]: any }) => any;
  onStepChange?: (stepIndex: number) => void;
  onSubmitSuccess?: (fields: { [key: string]: any }, sfFields?: { [key: string]: any }) => any;
  formRef?: (ref: any) => any;
  testId?: string;
  enableZoomInfo?: boolean;
};

type StepType = {
  columns?: 1 | 2;
  fields?: Array<FieldType>;
  template?: string; // template that loads specific fields: profile|feedback
};

type FieldType = {
  type: string; // either the field type or "custom"
  optional?: boolean;
  component?: 'input' | 'inputLocation' | 'inputDate' | 'segments' | 'chips' | 'select' | 'checkbox'; // REQUIRED FOR CUSTOM - the string name of the component
  name?: string; // REQUIRED FOR CUSTOM - the name of the field to pass to form state
  salesforceFieldId?: string; // REQUIRED FOR CUSTOM - salesforce field id
  salesforceValueTransformer?: (value: any) => any; // optional function to convert field value for salesforce submit
  props?: { [key: string]: any }; // props to pass to field component (useful to pass items, default value, or other stuff)
  renderIf?: (formValues: { [key: string]: any }) => boolean;
};

const templates = {
  profile: {
    columns: 2,
    fields: [{ type: 'firstName' }, { type: 'lastName' }, { type: 'businessEmail' }, { type: 'phone' }],
  },
  corporateDetails: {
    columns: 2,
    fields: [
      { type: 'jobTitle' },
      { type: 'companyName' },
      { type: 'companyWebsite' },
      { type: 'eventZipCode' },
      { type: 'numberEmployees', optional: true },
      { type: 'employeeWorkType' },
    ],
  },
  corporateFeedback: {
    columns: 1,
    fields: [
      { type: 'massageBenefitTypes' },
      {
        type: 'singleOrMultipleLocation',
        renderIf: (formValues) => {
          return formValues?.massageBenefitTypes?.includes('In-Office Chair Massage');
        },
      },
      { type: 'interestedOneTimeEvent' },
      { type: 'howToHelp', optional: true },
      { type: 'hearAbout' },
      { type: 'subscribeToNewsletter', optional: true },
    ],
  },
  feedback: {
    column: 1,
    fields: [{ type: 'hearAbout' }, { type: 'howToHelp', optional: true }],
  },
};
const sfFieldIdMap = {
  jobTitle: 'title',
  companyName: 'company',
  companyWebsite: 'url',
  numberEmployees: 'CompanySize__c',
  firstName: 'first_name',
  lastName: 'last_name',
  businessEmail: 'email',
  phone: 'phone',
  hearAbout: 'Heard_About_Zeel__c',
  howToHelp: 'How_Can_We_Help__c',
  eventZipCode: 'zip',
  zipCode: 'zip',
  subscribeToNewsletter: 'Newsletter__c',
  employeeWorkType: 'Employee_Work_Type__c',
  massageBenefitTypes: 'Massage_Benefit_Types__c',
  interestedOneTimeEvent: 'Interested_In__c',
  singleOrMultipleLocation: 'Multiple_Locations__c',
};
const componentsMap = {
  input: Input,
  inputLocation: InputLocation,
  inputDate: InputDate,
  segments: Segments,
  chips: Chips,
  select: Select,
  checkbox: Checkbox,
};
const hearAboutOptions = [
  { label: 'Referral', value: 'Referral' },
  { label: 'Online Search', value: 'Online Search' },
  { label: 'Conference/Event', value: 'Conference/Event' },
  { label: 'Other', value: 'Other' },
];
const numberEmployeesOptions = [
  { label: '1-10', value: '1-10' },
  { label: '11-50', value: '11-50' },
  { label: '51-200', value: '51-200' },
  { label: '201-500', value: '201-500' },
  { label: '501-1000', value: '501-1000' },
  { label: '1000+', value: '1000+' },
];
const employeeWorkTypeOptions = [
  { label: 'On-Site', value: 'On-Site' },
  { label: 'Hybrid', value: 'Hybrid' },
  { label: 'Remote', value: 'Remote' },
];
const massageBenefitTypesOptions = [
  { label: 'In-Office Chair Massage', value: 'In-Office Chair Massage' },
  { label: 'In-Home Table Massage', value: 'In-Home Table Massage' },
];
const interestedOneTimeEventOptions = [
  { label: 'One-Time', value: 'One-Time Event' },
  { label: 'Recurring', value: 'Recurring Event' },
];
const singleOrMultipleLocationOptions = [
  { label: 'Single Location', value: false },
  { label: 'Multiple Locations', value: true },
];

const LeadFormStepBlock: FC<Props> = ({
  steps: propSteps = [],
  mainButtonText,
  submitButtonText,
  topAnchor,
  onStepChange,
  additionalSalesforceFields,
  customSubmitHandler,
  onSubmitSuccess,
  submitDelay,
  id,
  title,
  description,
  descriptionClassName,
  successTitle,
  successDescription,
  finePrint,
  showTerms = true,
  customTerms,
  fixedHeight,
  formRef,
  className,
  stepClassName,
  testId = 'lead-form-step-block',
  enableZoomInfo,
}) => {
  const [activeStepIndex, setActiveStepIndex] = useState<number>(0);
  const [leadCreated, setLeadCreated] = useState<boolean>(false);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [localError, setLocalError] = useState<string>(null);

  const form = useForm();
  const zoomInfo = useZoomInfo({ disabled: !enableZoomInfo });

  // Method to get steps which transforms any template steps into the proper config
  const getSteps = useCallback(() => {
    return propSteps.map((step) => {
      const { template } = step;
      if (template) {
        if (!templates[template])
          log.error(
            `template '${template}' does not exist, and needs to be one of the following: ${Object.keys(templates).join(
              '|'
            )}`
          );
        return templates[template];
      }
      return step;
    });
  }, [propSteps]);

  // Returns the main button text based
  const getButtonLabel = useCallback(() => {
    const _steps = getSteps();

    if (activeStepIndex === steps.length - 1)
      return _steps.length === 1 ? mainButtonText : submitButtonText || 'Submit';
    if (activeStepIndex === 0) return mainButtonText || 'Connect with Us';
    if (activeStepIndex === _steps.length - 2) return 'Continue to Last Step';

    return 'Continue to Next Step';
  }, [activeStepIndex, mainButtonText, submitButtonText]);

  // Returns the styles based on the stepIndex in relation to the active step
  const getStepStyle = useCallback(
    (stepIndex: number) => {
      if (activeStepIndex === stepIndex) return { left: `${-100 * stepIndex}%` };
      if (activeStepIndex > stepIndex) return { left: `${-100 * stepIndex - 100}%` };
      if (activeStepIndex < stepIndex) return { left: `${-100 * stepIndex + 100}%` };
    },
    [activeStepIndex]
  );

  // Returns the field JSX based on the field config
  const getFieldFromConfig = (field: FieldType, focus = false) => {
    const commonProps = {
      required: field?.optional ? false : true,
      autoFocus: focus,
      ...(field?.props || {}),
    };

    const fieldTypeDict = {
      firstName: (
        <Input
          {...form.getProps('firstName')}
          label={`First Name ${field?.optional ? '(Optional)' : ''}`}
          {...commonProps}
          testId={`${testId}--first-name-input`}
        />
      ),
      lastName: (
        <Input
          {...form.getProps('lastName')}
          label={`Last Name ${field?.optional ? '(Optional)' : ''}`}
          {...commonProps}
          testId={`${testId}--last-input`}
        />
      ),
      businessEmail: (
        <Input
          {...form.getProps('businessEmail')}
          type='email'
          label={`Business Email Address ${field?.optional ? '(Optional)' : ''}`}
          {...commonProps}
          testId={`${testId}--business-email-input`}
        />
      ),
      phone: (
        <Input
          {...form.getProps('phone')}
          type='phone'
          label={`Phone Number ${field?.optional ? '(Optional)' : ''}`}
          {...commonProps}
          testId={`${testId}--phone-input`}
        />
      ),
      jobTitle: (
        <Input
          {...form.getProps('jobTitle')}
          label={`Job Title ${field?.optional ? '(Optional)' : ''}`}
          {...commonProps}
          testId={`${testId}--job-title-input`}
        />
      ),
      companyName: (
        <Input
          {...form.getProps('companyName')}
          label={`Company Name ${field?.optional ? '(Optional)' : ''}`}
          {...commonProps}
          testId={`${testId}--company-name-input`}
        />
      ),
      companyWebsite: (
        <Input
          {...form.getProps('companyWebsite')}
          label={`Company Website ${field?.optional ? '(Optional)' : ''}`}
          placeholder='https://'
          {...commonProps}
          testId={`${testId}--company-website-input`}
        />
      ),
      numberEmployees: (
        <Chips
          {...form.getProps('numberEmployees')}
          label={`Number of US Employees ${field?.optional ? '(Optional)' : ''}`}
          items={numberEmployeesOptions}
          unselectable
          {...commonProps}
          testId={`${testId}--nb-employees-chips`}
        />
      ),
      hearAbout: (
        <Chips
          {...form.getProps('hearAbout')}
          label={`How did you hear about Zeel? ${field?.optional ? '(Optional)' : ''}`}
          items={hearAboutOptions}
          {...commonProps}
          testId={`${testId}--hear-about-chips`}
        />
      ),
      howToHelp: (
        <Input
          {...form.getProps('howToHelp')}
          multiline
          rows={3}
          label={`How can we help? ${field?.optional ? '(Optional)' : ''}`}
          placeholder='Tell us more about your event and what you’re looking to do.'
          {...commonProps}
          testId={`${testId}--how-can-we-help-input`}
        />
      ),
      eventZipCode: (
        <Input
          {...form.getProps('eventZipCode')}
          label={`Event Zip Code ${field?.optional ? '(Optional)' : ''}`}
          max={5}
          {...commonProps}
          testId={`${testId}--event-zip-code-input`}
        />
      ),
      zipCode: (
        <Input
          {...form.getProps('zipCode')}
          label={`Zip Code ${field?.optional ? '(Optional)' : ''}`}
          max={5}
          {...commonProps}
          testId={`${testId}--zip-code-input`}
        />
      ),
      subscribeToNewsletter: (
        <Checkbox
          {...form.getProps('subscribeToNewsletter')}
          label={`I'd like to receive news, updates, and offers from Zeel.`}
          defaultValue={true}
          {...commonProps}
          testId={`${testId}--subscribe-to-newsletter-checkbox`}
        />
      ),
      employeeWorkType: (
        <Segments
          {...form.getProps('employeeWorkType')}
          label={`Where do employees currently work? ${field?.optional ? '(Optional)' : ''}`}
          wrapLabel
          items={employeeWorkTypeOptions}
          {...commonProps}
          testId={`${testId}--employee-work-type-segments`}
        />
      ),
      massageBenefitTypes: (
        <Chips
          {...form.getProps('massageBenefitTypes')}
          label={`Which type(s) of employee massage benefits are you interested in learning about? Select all that apply: ${
            field?.optional ? '(Optional)' : ''
          }`}
          wrapLabel
          multiple
          items={massageBenefitTypesOptions}
          {...commonProps}
          testId={`${testId}--massage-benefit-type-chips`}
        />
      ),
      interestedOneTimeEvent: (
        <Segments
          {...form.getProps('interestedOneTimeEvent')}
          label={`Are you interested in a one-time event or recurring employee benefit? ${
            field?.optional ? '(Optional)' : ''
          }`}
          wrapLabel
          items={interestedOneTimeEventOptions}
          {...commonProps}
          testId={`${testId}--interest-in-one-time-event-segments`}
        />
      ),
      singleOrMultipleLocation: (
        <Segments
          {...form.getProps('singleOrMultipleLocation')}
          label={`Are you hosting your events in a single location or multiple locations? ${
            field?.optional ? '(Optional)' : ''
          }`}
          wrapLabel
          items={singleOrMultipleLocationOptions}
          {...commonProps}
          testId={`${testId}--single-or-multiple-location-segments`}
        />
      ),
    };

    if (field?.renderIf && !field.renderIf(form.getValues())) {
      return null;
    }

    if (field?.type === 'custom') {
      let C = null;
      if (typeof field?.component === 'string') {
        if (!componentsMap[field?.component])
          log.error(
            `component '${field?.component}' specified does not exist, and needs to be one of the following values: input|inputLocation|inputDate|segments|chips|select. Fallback to Input component.`
          );
        C = componentsMap[field?.component] || Input;
      } else if (field?.component) {
        C = field.component;
      }

      if (!C) return null;
      return <C {...form.getProps(field?.name)} {...commonProps} />;
    }

    return fieldTypeDict[field?.type];
  };

  // Will check if all fields of the step at index are valid
  const isStepValid = (stepIndex: number) => {
    const _steps = getSteps();
    const allRequiredFields = (_steps[stepIndex]?.fields || [])
      .map((field) => {
        if (field?.optional) return null;
        if (field?.renderIf && !field.renderIf(form.getValues())) return null;
        return field?.type === 'custom' ? field?.name : field?.type;
      })
      .filter((a) => a);

    return form.isValid(allRequiredFields);
  };

  const scrollToTitle = useCallback(() => {
    document
      .querySelector(topAnchor || '#lead-form-title-anchor')
      ?.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
  }, [topAnchor]);

  // Will go back 1 step
  const back = useCallback(() => {
    const nextStepIndex = Math.max(0, activeStepIndex - 1);
    setActiveStepIndex(nextStepIndex);
    onStepChange?.(nextStepIndex);
    scrollToTitle();
  }, [activeStepIndex]);

  // Will either increment active step, or submit to salesforce using form values
  const proceed = useCallback(async () => {
    const _steps = getSteps();

    // Last step
    if (activeStepIndex === _steps.length - 1) {
      setSubmitting(true);

      const allRawFields: any = {};
      let sfFields: any = {};

      // Extracting all
      _steps.forEach((step) => {
        const { fields = [] } = step;
        fields.forEach((field) => {
          const { type, name, salesforceFieldId, salesforceValueTransformer, renderIf } = field;
          const isRendered = !renderIf ? true : renderIf(form.getValues());
          if (type === 'custom') {
            allRawFields[name] = form.getValue(name);
            const val = salesforceValueTransformer
              ? salesforceValueTransformer(form.getValue(name))
              : form.getValue(name);
            if (val !== undefined && val !== null && isRendered) {
              sfFields[salesforceFieldId] = val;
            }
          } else {
            allRawFields[type] = form.getValue(type);
            const sfId = sfFieldIdMap[type];
            const val = form.getValue(type);
            if (val !== undefined && val !== null && isRendered) {
              sfFields[sfId] = val;
            }
          }
        });
      });

      // Merging with main sf fields and additional ones if specified
      sfFields = {
        oid: process.env.REACT_APP_SALESFORCE_ID,
        lead_source: 'Landing Page Lead Form',
        status: 'New',
        retURL: window.location.href,
        industry: 'Other', // omitting this field will still allow lead creation
        recordType: '01241000001EGM3',
        '00N4100000e91BV': moment().format('DD/MM/YYYY'),
        ...sfFields,
        ...(additionalSalesforceFields || {}),
      };

      if (zoomInfo?.match) {
        const zoomToSFMap = {
          businessEmail: 'email',
          companyCity: 'Company_City__c',
          companyEmployees: 'Number_of_Employees__c',
          companyName: 'company',
          companyPhone: 'Company_Phone__c',
          companyRevenue: 'revenue',
          companyState: 'Company_State__c',
          companyStreet: 'Company_Street__c',
          companyWebsite: 'url',
          companyZipCode: 'Company_PostalCode__c',
          contactCity: 'city',
          contactState: 'state',
          contactLinkedinUrl: 'LinkedIn_URL__c',
          contactStreet: 'street',
          contactZip: 'PostalCode',
          directPhoneNumber: 'Phone',
          mobilePhoneNumber: 'MobilePhone',
          jobTitle: 'title',
          primaryIndustry: 'Industry',
          supplementalEmail: 'Supplemental_Email__c',
        };

        // Mapping ZoomInfo fields to Salesforce fields
        Object.keys(zoomToSFMap).forEach((key) => {
          const sfKey = zoomToSFMap[key];
          if (sfKey && !isNil(zoomInfo.match[key])) {
            sfFields[sfKey] = zoomInfo.match[key];
          }
        });
      }

      if (customSubmitHandler) {
        try {
          await customSubmitHandler(allRawFields, sfFields);
        } catch (error) {
          log.error(error);
          setSubmitting(false);
          setLocalError('An error occurred while submitting the form, please try again later.');
          return;
        }
      } else {
        helpers.submitSalesforceLead(sfFields);
      }

      setTimeout(() => {
        setLeadCreated(true);
        setSubmitting(false);
        setTimeout(() => {
          scrollToTitle();
        }, 0);
        if (onSubmitSuccess) onSubmitSuccess(allRawFields, sfFields);
      }, submitDelay || 300);
      return;
    }

    // All other steps
    const nextStepIndex = activeStepIndex + 1;
    setActiveStepIndex(nextStepIndex);
    setTimeout(() => {
      scrollToTitle();
    }, 0);
    onStepChange?.(nextStepIndex);
  }, [
    activeStepIndex,
    customSubmitHandler,
    onSubmitSuccess,
    additionalSalesforceFields,
    submitDelay,
    form,
    zoomInfo?.match,
  ]);

  const restart = () => {
    form.reset();
    setLeadCreated(false);
    setActiveStepIndex(0);
    setTimeout(() => {
      scrollToTitle();
    }, 0);
  };

  const steps = getSteps();

  return (
    <div
      id={id}
      className={clsx(styles.base, { [styles.fixedHeight]: fixedHeight, [styles.variableHeight]: !fixedHeight })}
      ref={formRef ? (r) => formRef(r) : null}
      data-testid={`${testId}`}
    >
      {leadCreated && (
        <div className={clsx(styles.glContainerSmall, styles.container, className)}>
          <IconSvg className={styles.successIcon} name='checkmark-filled-circle' color='lush-alt' size={60} />
          {successTitle !== false && (
            <h2 id='lead-form-title-anchor'>{successTitle || "Thanks! We'll be in touch soon."}</h2>
          )}
          {successDescription !== false && (
            <p>{successDescription || 'Our team will reach out to you to help plan your wellness event.'}</p>
          )}
          <div className={styles.footer}>
            <FieldRow alignX='center'>
              <Button id='lead-new-request' className={styles.button} type='secondary' onClick={restart}>
                Submit Another Request
              </Button>
            </FieldRow>
          </div>
        </div>
      )}
      {!leadCreated && (
        <div className={clsx(styles.glContainerSmall, styles.container, className)}>
          {title !== false && (
            <h2 id='lead-form-title-anchor' data-testid={`${testId}--title`}>
              {title || 'Interested in a custom wellness solution?'}
            </h2>
          )}
          {description !== false && (
            <p data-testid={`${testId}--description`} className={descriptionClassName}>
              {description}
            </p>
          )}
          {localError && (
            <Notification type='error' testId={`${testId}--error-notification`}>
              {localError}
            </Notification>
          )}
          <div className={styles.form}>
            {steps.map((step, i) => {
              const { columns = 1, fields = [] } = step || {};
              return (
                <div
                  key={i}
                  className={clsx(
                    styles.step,
                    { [styles['step--hidden']]: activeStepIndex < i },
                    { [styles['step--completed']]: activeStepIndex > i },
                    stepClassName
                  )}
                  style={getStepStyle(i)}
                  data-testid={`${testId}--step-${i}`}
                >
                  <FieldRow fit={columns} wrap={columns} alignX='stretch'>
                    {fields.map((field, fi) => {
                      return getFieldFromConfig(field, activeStepIndex === i && i > 0 && fi === 0);
                    })}
                  </FieldRow>
                </div>
              );
            })}
          </div>
          <div className={styles.footer}>
            <FieldRow alignX='center' reverseDesktop>
              <Button
                id='lead-continue-button'
                className={styles.button}
                type='primary'
                disabled={!isStepValid(activeStepIndex) || submitting || zoomInfo?.matching}
                onClick={proceed}
                testId={`${testId}--main-button`}
              >
                {getButtonLabel()}
              </Button>
              {steps.length > 1 && activeStepIndex !== 0 && (
                <Button
                  id='lead-back-button'
                  className={styles.button}
                  type='secondary'
                  onClick={back}
                  testId={`${testId}--back-button`}
                >
                  Back
                </Button>
              )}
            </FieldRow>
            {finePrint && <div className={styles.finePrint}>{finePrint}</div>}
            {showTerms && (
              <div className={styles.terms}>
                {activeStepIndex === steps.length - 1 &&
                  (customTerms || (
                    <>
                      By selecting &apos;{getButtonLabel()}&apos;, I confirm that I have read and agreed to Zeel's{' '}
                      <Link
                        styled
                        href={routes.TERMS_OF_USE({ host: true })}
                        target='_blank'
                        testId={`${testId}--terms-link`}
                      >
                        Terms of Use
                      </Link>{' '}
                      and{' '}
                      <Link
                        styled
                        href={routes.PRIVACY_POLICY({ host: true })}
                        target='_blank'
                        testId={`${testId}--privacy-policy-link`}
                      >
                        Privacy Policy
                      </Link>
                    </>
                  ))}
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default LeadFormStepBlock;
