import { FormEvent, MouseEvent, ReactNode, useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';

import clsx from 'clsx';

import { helpers } from 'utils';
import { ThemeProvider, themes } from 'utils/theme-context';

import Button from 'components/common/Button';
import IconSvg from 'components/common/IconSvg';
import Loader from 'components/common/Loader';
import Notification from 'components/common/Notification';

import Backdrop from '../Backdrop';
import styles from './style.module.scss';

export type PresetActionsType = {
  confirm: () => void;
  close: () => void;
};

export type ActionType = {
  label: string;
  action?: 'confirm' | 'close' | 'submit';
  variant?: string;
  onClick?: (e: MouseEvent<HTMLButtonElement>) => void;
  main?: boolean;
  disabled?: boolean;
  loading?: boolean;
};

export type ModalProps = {
  id?: string;
  title?: string | JSX.Element;
  description?: string | ReactNode;
  hidden?: boolean;
  loading?: boolean;
  alert?: string;
  alertSeverity?: 'error' | 'success' | 'info' | 'warning' | undefined;
  icon?: string;
  iconColor?: string;
  imageUrl?: string;
  actions?: Array<ActionType>;
  className?: string;
  iconsBarClassName?: string;
  headerClassName?: string;
  bodyClassName?: string;
  footerClassName?: string;
  backdropClose?: boolean;
  warnBeforeClosing?: boolean;
  closable?: boolean;
  onClick?: (e: MouseEvent<HTMLFormElement>) => void;
  onBack?: (e: MouseEvent<SVGElement>) => void;
  children?: ReactNode;
  closing?: boolean;
  onClose?: (result?: any) => void;
  onSubmit?: (e: FormEvent<EventTarget>) => void;
  mobileType?: 'drawer' | 'fullscreen';
  mobileStackButtons?: boolean;
  stackedButtons?: boolean;
  mobileCenterContent?: boolean;
  centerContent?: boolean;
  disableActionsFocus?: boolean;
  theme?: string;
  customContentAfterActions?: ReactNode;
  slim?: boolean;
  testId?: string;
};

export default function Modal({
  id,
  title,
  description,
  hidden,
  loading,
  alert,
  alertSeverity = 'error',
  icon,
  iconColor = 'theme',
  imageUrl,
  actions,
  className,
  iconsBarClassName,
  headerClassName,
  bodyClassName,
  footerClassName,
  backdropClose = true,
  warnBeforeClosing,
  closable = true,
  onClick: onRootClick,
  onBack,
  children,
  closing,
  onClose,
  onSubmit,
  mobileType = null,
  mobileStackButtons = false,
  stackedButtons = false,
  mobileCenterContent,
  centerContent = false,
  disableActionsFocus,
  theme,
  customContentAfterActions,
  slim,
  testId = 'modal-v2',
}: ModalProps) {
  const [show, setShow] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const formRef = useRef<any>();

  const onResize = useCallback(() => {
    if (formRef.current && helpers.isMobileWidth()) formRef.current.style.maxHeight = `${window.innerHeight}px`;
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setShow(true);
    });
  }, []);

  useLayoutEffect(() => {
    window.addEventListener('resize', onResize);
    if (formRef.current && helpers.isMobileWidth()) formRef.current.style.maxHeight = `${window.innerHeight}px`;
  }, [formRef?.current]);

  const close = async (result = null) => {
    if (onClose) {
      if (warnBeforeClosing) {
        const res = await window.confirm(
          typeof warnBeforeClosing === 'string'
            ? warnBeforeClosing
            : 'Are you sure you want to close this modal? All unsaved changes will be lost.'
        );
        if (!res) return;
      }
      onClose(result);
    }
  };

  const submit = async (e: FormEvent<EventTarget>) => {
    if (e) e.preventDefault();
    setSubmitting(true);
    if (onSubmit) await onSubmit(e);
    setSubmitting(false);
  };

  const content = (
    <Backdrop
      hidden={hidden}
      closing={closing}
      onClick={closable && backdropClose ? () => close() : null}
      margin={mobileType === 'fullscreen' || mobileType === 'drawer'}
      testId={`${testId}--backdrop`}
    >
      <form
        id={id}
        ref={formRef}
        onSubmit={submit}
        onClick={onRootClick ? onRootClick : (e) => e.stopPropagation()}
        className={clsx(
          styles.base,
          {
            [styles.show]: show && !closing,
            [styles[`mobile-${mobileType}`]]: styles[`mobile-${mobileType}`],
            [styles.hasButtons]: actions?.length > 0,
            [styles.slim]: slim,
          },
          className
        )}
        data-testid={`${testId}`}
      >
        <div className={clsx(styles.iconsBar, iconsBarClassName)}>
          <div className={styles.iconsWrapper}>
            {onBack && <IconSvg name='arrow-left' size={24} onClick={onBack} testId={`${testId}--back-icon`} />}
          </div>
          <div className={styles.iconsWrapper}>
            {closable && <IconSvg name='x' size={24} onClick={() => close()} testId={`${testId}--close-icon`} />}
          </div>
        </div>
        <div className={clsx(styles.header, headerClassName)}>
          {icon && (
            <IconSvg
              className={styles.mainIcon}
              name={icon}
              color={iconColor}
              size={60}
              testId={`${testId}--main-icon`}
            />
          )}
          {imageUrl && (
            <img className={styles.mainImage} src={imageUrl} alt={'main-image'} data-testid={`${testId}--main-image`} />
          )}
          {title && (
            <h1 className={styles.title} data-testid={`${testId}--title`}>
              {title}
            </h1>
          )}
          {description && (
            <p className={styles.description} data-testid={`${testId}--description`}>
              {description}
            </p>
          )}
        </div>
        <div className={styles.bodyWrapper}>
          <div
            className={clsx(styles.body, bodyClassName, {
              [styles['body--centerContent']]: centerContent,
              [styles['body--mobileCenterContent']]:
                mobileType === 'fullscreen' ? mobileCenterContent || false : mobileCenterContent || true,
            })}
          >
            {alert && (
              <Notification className={styles.alert} type={alertSeverity} testId={`${testId}--notification`}>
                {alert}
              </Notification>
            )}
            {children}
          </div>
        </div>
        <div className={clsx(styles.footer, footerClassName)}>
          <div
            className={clsx(styles.actions, {
              [styles['actions--mobileStacked']]: mobileStackButtons,
              [styles['actions--stacked']]: stackedButtons,
            })}
          >
            {(actions || []).map((action: ActionType) => {
              const { label, action: presetAction, variant, onClick, main, disabled, loading: _loading } = action;
              const presetActions: PresetActionsType = {
                confirm: () => close(true),
                close: () => close(),
              };
              const handler = (presetAction && presetActions[presetAction]) || onClick;
              return (
                <Button
                  key={label}
                  type={
                    variant || (main || presetAction === 'submit' || actions.length === 1 ? 'primary' : 'secondary')
                  }
                  loading={(presetAction === 'submit' && !onClick && submitting) || _loading}
                  submit={(main || presetAction === 'submit') && !onClick}
                  disabled={disabled || _loading}
                  onClick={handler}
                  unfocusable={disableActionsFocus}
                  testId={
                    main || presetAction === 'confirm'
                      ? `${testId}--main-button`
                      : presetAction === 'close'
                      ? `${testId}--cancel-button`
                      : `${testId}--button`
                  }
                >
                  {label}
                </Button>
              );
            })}
          </div>
          {customContentAfterActions}
        </div>
        <div className={styles.loaderContainer}>
          <Loader className={styles.loader} loading={loading} />
        </div>
        {loading && <div className={styles.loaderOverlay} />}
      </form>
    </Backdrop>
  );

  if (theme && !!themes[theme]) {
    return <ThemeProvider value={theme}>{content}</ThemeProvider>;
  }
  return content;
}
