import { createContext, useContext } from 'react';

import {
  PaymentCollectionType,
  PaymentMethodType,
  PaymentOption,
  PaymentProcessor,
} from '../../redux/apis/OG/payment/types';
import { ApplePayPaymentRequestClientConfig } from './native/ApplePay';
import { InsuranceProfileType, PMCPaymentContextConfig, PMCRenderMode, PMCResponse } from './types';

// PMC Context types
export type PMCContextType = PMCPaymentContextConfig & {
  mode: PMCRenderMode;
  prefillEmail?: string;
  paymentOption: PaymentOption;
  collectionType?: PaymentCollectionType;
  entity?: PaymentMethodType | InsuranceProfileType;
  processor: PaymentProcessor;
  initialSelectedEntityId?: string;
  setCreatedAsDefault?: boolean;
  setSelectedAsDefault?: boolean;
  listApplePay?: ApplePayPaymentRequestClientConfig;
  clientToken: string;
  devMode?: boolean;
  // Handlers
  complete: (res: PMCResponse) => void | Promise<void>;
  fail: (e: string) => void | Promise<void>;
  changeMode: (
    m: PMCRenderMode,
    params?: {
      updateEntity?: { id: string; paymentOption: PaymentOption };
      dueToNoMethods?: boolean;
    }
  ) => void;
  canBack: boolean;
};

export const PMCContext = createContext<PMCContextType>({
  mode: undefined,
  prefillEmail: undefined,
  paymentOption: PaymentOption.CreditCard,
  collectionType: undefined,
  entity: undefined,
  processor: PaymentProcessor.Braintree,
  usedFor: undefined,
  amount: undefined,
  bookingData: undefined,
  initialSelectedEntityId: undefined,
  setCreatedAsDefault: false,
  setSelectedAsDefault: false,
  listApplePay: undefined,
  clientToken: '',
  complete: () => undefined,
  fail: () => undefined,
  changeMode: () => undefined,
  canBack: false,
});

export const usePMCContext = () => useContext<PMCContextType>(PMCContext);
