import camelCase from 'lodash/camelCase';
import isNil from 'lodash/isNil';
import omitBy from 'lodash/omitBy';
import moment from 'moment';

import { helpers } from 'utils';

import { GenericResponse, OGApi, TAGS } from '..';
import { AddressCategory } from './constants';
import {
  AccountTypesMapping,
  AdditionalAttributesMapping,
  AddressMapping,
  ClientBenefitsEligibilityMapping,
  CreditHistoryMapping,
  GiftedMembershipMapping,
  MembershipMapping,
  PersonMapping,
  PlanMapping,
  UserMapping,
} from './mapping';
import {
  AccountTypesType,
  AdditionalAttributesType,
  AddressType,
  ClientBenefitsEligibilityType,
  CreditHistoryType,
  GenderEnum,
  GiftCertificateOptionsType,
  GiftPayloadType,
  GiftedMembershipType,
  KemtaiActivity,
  KemtaiActivityResult,
  MembershipPayloadType,
  MembershipType,
  PersonType,
  PlanType,
  SourceAccountTypesType,
  SourceAdditionalAttributesType,
  SourceAddressType,
  SourceCreditHistoryType,
  SourceGiftedMembershipType,
  SourceMembershipType,
  SourcePlanType,
  SourceUserType,
  UserType,
} from './types';
import { identifyWith3rdParties } from './utils';

export const userApi = OGApi.injectEndpoints({
  endpoints: (builder) => ({
    /**
     * Get currently logged in user details
     */
    getMe: builder.query<UserType, void>({
      query: () => {
        return {
          url: '/api/v1/member/status',
          method: 'GET',
          params: {
            _data: 'member:membership_status',
          },
          validateStatus: (response, result) => response.status === 200 && !result.errors,
        };
      },
      transformResponse: (responseData: {
        member: SourceUserType;
        membership_status: { allow_upgrade?: boolean; plan_name?: string };
      }) => {
        const { member, membership_status } = responseData;
        const m: SourceUserType = member;

        m.plan_name = membership_status?.plan_name;
        m.allow_upgrade = membership_status?.allow_upgrade;

        return UserMapping.apply(m, undefined, { hideLogs: true });
      },
      onQueryStarted: async (arg, { queryFulfilled }) => {
        try {
          const { data: member } = await queryFulfilled;
          if (member) {
            identifyWith3rdParties(member);
          }
        } catch (err) {
          console.log(err);
        }
      },
      providesTags: () => {
        return [TAGS.USER];
      },
    }),
    /**
     * Get currently logged in user details
     */
    getTokenDetails: builder.query<any, void>({
      query: () => {
        return {
          url: '/api/v1/member/status',
          method: 'GET',
          params: {
            _data: 'token_details',
          },
          validateStatus: (response, result) => response.status === 200 && !result.errors,
        };
      },
      transformResponse: (responseData) => {
        const tokenDetails: any = helpers.renameKeys(responseData?.token_details, (k) => camelCase(k));
        return tokenDetails;
      },
      providesTags: () => {
        return [TAGS.TOKEN];
      },
    }),
    /**
     * Get user's account types
     */
    getAccountTypes: builder.query<AccountTypesType, void>({
      query: () => {
        return {
          url: '/api/v1/member/account/type',
          method: 'GET',
          params: {
            _data: '',
          },
          validateStatus: (response, result) => response.status === 200 && !result.errors,
        };
      },
      transformResponse: (responseData: any) => {
        const accountTypes: SourceAccountTypesType = responseData;
        return AccountTypesMapping.apply(accountTypes);
      },
      providesTags: () => {
        return [TAGS.USER];
      },
    }),

    /**
     * Get user's additional attributes
     */
    getAdditionalAttributes: builder.query<AdditionalAttributesType, void>({
      query: () => {
        return {
          url: '/api/v1/member/additional_attributes',
          method: 'GET',
          params: {
            _data: '',
          },
          validateStatus: (response, result) => response.status === 200 && !result.errors,
        };
      },
      transformResponse: (responseData: { additional_attributes: SourceAdditionalAttributesType }) => {
        return AdditionalAttributesMapping.apply(responseData.additional_attributes || {});
      },
      providesTags: () => {
        return [TAGS.USER];
      },
    }),

    /**
     * Get kemtai token to use with KemtaiProvider
     */
    getKemtaiToken: builder.query<string, void>({
      query: () => {
        return {
          url: '/api/v1/member/kemtai/start_session',
          method: 'POST',
          params: {
            _data: '',
          },
          validateStatus: (response, result) => response.status === 200 && !result.errors,
        };
      },
      keepUnusedDataFor: 0,
      transformResponse: (responseData: { session: { token: string } }) => {
        return responseData?.session?.token;
      },
      providesTags: () => {
        return [TAGS.USER];
      },
    }),

    /**
     * Get all kemtai activities
     */
    getKemtaiActivities: builder.query<KemtaiActivity[], void>({
      query: () => {
        return {
          url: '/api/v1/member/kemtai/activities',
          method: 'GET',
          params: {
            _data: '',
          },
          validateStatus: (response, result) => response.status === 200 && !result.errors,
        };
      },
      keepUnusedDataFor: 0,
      transformResponse: (responseData: { activities: KemtaiActivity[] }) => {
        return responseData?.activities || [];
      },
    }),

    /**
     * Get kemtai activity by id
     */
    getKemtaiActivityResults: builder.query<KemtaiActivityResult[], string>({
      query: (activityResultId) => {
        return {
          url: `/api/v1/member/kemtai/activity_results/${activityResultId}`,
          method: 'GET',
          params: {
            _data: '',
          },
          validateStatus: (response, result) => response.status === 200 && !result.errors,
        };
      },
      keepUnusedDataFor: 0,
      transformResponse: (responseData: { activity: KemtaiActivityResult[] }) => {
        return responseData?.activity || [];
      },
    }),

    /**
     * Send mobile phone verification code to the user
     */
    sendMobilePhoneVerificationCode: builder.mutation<null, void>({
      query: () => {
        return {
          url: '/api/v1/member/send_mobile_verification',
          method: 'GET',
          params: {
            _data: '',
          },
          validateStatus: (response, result) => response.status === 200 && !result.errors,
        };
      },
    }),
    /**
     * Verifies the user's mobile phone using the code provided
     */
    verifyMobilePhone: builder.mutation<void, string>({
      query: (code) => {
        return {
          url: '/api/v1/member/validate_mobile',
          method: 'GET',
          params: {
            mobile_confirm_code: code,
            _data: '',
          },
          validateStatus: (response, result) => response.status === 200 && !result.errors,
        };
      },
      invalidatesTags: [TAGS.USER],
    }),
    /**
     * Verify user's identity via emailage
     */
    verifyUserIdentity: builder.mutation<void, { forceJumio?: boolean } | void>({
      query: (options) => {
        return {
          url: '/api/v1/member/verifyidentitywithemailage',
          method: 'POST',
          body: {
            run_full_account_setup_check: true,
            force_jumio: (options as any)?.forceJumio || false,
          },
          validateStatus: (response, result) => response.status === 200 && !result.errors,
        };
      },
      invalidatesTags: [TAGS.USER],
    }),
    /**
     * Gets the Jumio Iframe url to verify user's identity
     */
    getJumioIframeUrl: builder.query<string, { successUrl: string; errorUrl: string }>({
      query: ({ successUrl, errorUrl }) => {
        return {
          url: '/api/v1/member/jumio/web_iframe',
          method: 'GET',
          params: {
            success_url: successUrl,
            error_url: errorUrl,
            _data: '',
          },
          validateStatus: (response, result) => response.status === 200 && !result.errors,
        };
      },
      transformResponse: (responseData: any) => {
        return responseData?.iframe_url;
      },
    }),
    /**
     * Get user's benefits eligibility
     */
    getClientBenefitsEligibility: builder.query<ClientBenefitsEligibilityType | null, void>({
      queryFn: async (_arg, _queryApi, _extraOptions, fetchWithBQ) => {
        const baseResult = await fetchWithBQ({
          url: '/api/v1/member/client_benefits_eligibility',
          method: 'GET',
          params: {
            _data: '',
          },
          validateStatus: (response, result) => response.status === 200 && !result.errors,
          options: {
            skipErrorLogging: true,
          },
        });
        return baseResult.error ? { data: null } : { data: ClientBenefitsEligibilityMapping.apply(baseResult.data) };
      },
    }),
    /**
     * Update the user's password when coming from a tokenized link
     */
    updatePasswordFromTokenizedFlow: builder.mutation<GenericResponse, { email: string; password: string }>({
      query: ({ email, password }) => {
        return {
          url: '/api/v1/member/update',
          method: 'POST',
          body: {
            email,
            password,
            password1: password,
            _data: '',
          },
          validateStatus: (response, result) => {
            return response.status === 200 && !result.errors;
          },
        };
      },
      invalidatesTags: [TAGS.USER],
    }),

    /**
     * Send reset password email
     */
    sendResetPassword: builder.mutation<null, string>({
      query: (email) => {
        return {
          url: '/api/v1/member/password_reset',
          method: 'POST',
          body: {
            email,
          },
          validateStatus: (response, result) => response.status === 200 && !result.errors,
        };
      },
    }),

    /**
     * Creates a member account and logs the user in
     */
    updateUser: builder.mutation<
      GenericResponse,
      Partial<UserType> & {
        acceptTerms?: boolean;
      }
    >({
      queryFn: async (_arg, _queryApi, _extraOptions, fetchWithBQ) => {
        const sourceData: SourceUserType = UserMapping.reverse(_arg as UserType);

        const data: any = {};
        if (sourceData.personal_notes) data.personal_notes = sourceData.personal_notes;
        if (sourceData.mobile) data.mobile = sourceData.mobile;
        if (sourceData.zip) data.zip = sourceData.zip;
        if (_arg.acceptTerms) data.transaction_terms = 1;

        let baseUpdateResult;
        if (Object.keys(data)?.length) {
          baseUpdateResult = await fetchWithBQ({
            url: '/api/v1/member/update',
            method: 'POST',
            body: {
              _data: '',
              ...omitBy(data, isNil),
            },
            validateStatus: (response, result) => response.status === 200 && !result.errors,
          });
          if (baseUpdateResult.error) return { error: baseUpdateResult.error };
        }

        const namesData: any = {};
        if (sourceData.fname) namesData.fname = sourceData.fname;
        if (sourceData.lname) namesData.lname = sourceData.lname;
        if (Object.keys(namesData)?.length) {
          baseUpdateResult = await fetchWithBQ({
            url: '/api/v1/member/update/name',
            method: 'POST',
            body: namesData,
            validateStatus: (response, result) => response.status === 200 && !result.errors,
          });
          if (baseUpdateResult.error) return { error: baseUpdateResult.error };
        }

        // email update
        if (sourceData.email) {
          baseUpdateResult = await fetchWithBQ({
            url: '/api/v1/member/update/email',
            method: 'POST',
            body: {
              email: sourceData.email,
            },
            validateStatus: (response, result) => response.status === 200 && !result.errors,
          });
          if (baseUpdateResult.error) return { error: baseUpdateResult.error };
        }

        if (sourceData.sex) {
          baseUpdateResult = await fetchWithBQ({
            url: '/api/v1/member/update/gender',
            method: 'POST',
            body: {
              sex: sourceData.sex,
            },
            validateStatus: (response, result) => response.status === 200 && !result.errors,
          });
          if (baseUpdateResult.error) return { error: baseUpdateResult.error };
        }

        if (sourceData.birthday) {
          baseUpdateResult = await fetchWithBQ({
            url: '/api/v1/member/update/birthday',
            method: 'POST',
            body: {
              birthday: sourceData.birthday,
            },
            validateStatus: (response, result) => response.status === 200 && !result.errors,
          });

          if (sourceData.mobile) {
            baseUpdateResult = await fetchWithBQ({
              url: '/api/v1/member/update/phone',
              method: 'POST',
              body: {
                mobile: sourceData.mobile,
              },
              validateStatus: (response, result) => response.status === 200 && !result.errors,
            });
          }

          if (baseUpdateResult.error) return { error: baseUpdateResult.error };
        }

        return baseUpdateResult?.data
          ? {
              data: baseUpdateResult.data,
            }
          : { error: baseUpdateResult.error };
      },
      invalidatesTags: [TAGS.USER],
    }),

    /**
     * Update user's email
     */
    updateUserEmail: builder.mutation<GenericResponse, string>({
      queryFn: async (email, _queryApi, _extraOptions, fetchWithBQ) => {
        // email update
        const res = await fetchWithBQ({
          url: '/api/v1/member/update/email',
          method: 'POST',
          body: {
            email,
          },
          validateStatus: (response, result) => response.status === 200 && !result.errors,
        });

        return res?.data
          ? {
              data: res.data,
            }
          : { error: res.error };
      },
    }),

    /**
     * Update user's password
     */
    updateUserPassword: builder.mutation<
      GenericResponse,
      {
        newPassword: string;
        oldPassword: string;
        confirmPassword: string;
      }
    >({
      queryFn: async (_arg, _queryApi, _extraOptions, fetchWithBQ) => {
        const data = {
          password: _arg.newPassword,
          old_password: _arg.oldPassword,
          password1: _arg.confirmPassword,
        };

        const res = await fetchWithBQ({
          url: '/api/v1/member/update',
          method: 'POST',
          body: {
            _data: '',
            ...omitBy(data, isNil),
          },
          validateStatus: (response, result) => response.status === 200 && !result.errors,
        });

        return res?.data
          ? {
              data: res.data,
            }
          : { error: res.error };
      },
    }),

    /**
     * Update sign-up data
     */
    updateUserSignupData: builder.mutation<
      { id: string },
      {
        firstName: string;
        lastName: string;
        gender: GenderEnum;
        zipCode: string;
        mobile: string;
        birthdate: string;
        tableMassageFlow?: boolean;
      }
    >({
      query: ({ firstName, lastName, gender, zipCode, mobile, birthdate, tableMassageFlow = null }) => {
        const day = moment(birthdate).date();
        const month = moment(birthdate).month() + 1;
        const year = moment(birthdate).year();
        return {
          url: '/api/v1/member/update/update_signup',
          method: 'POST',
          body: {
            _data: '',
            fname: firstName,
            lname: lastName,
            sex: gender,
            zip: zipCode,
            mobile,
            birthdate_year: year,
            birthdate_month: month,
            birthdate_day: day,
            ...(tableMassageFlow ? { table_massage_flow: tableMassageFlow } : {}),
          },
          validateStatus: (response, result) => response.status === 200 && !result.errors,
        };
      },
      transformResponse: (responseData: { id: string }) => {
        return { id: responseData?.id + '' };
      },
      invalidatesTags: [TAGS.USER],
    }),

    /**
     * Get user's addresses
     */
    getAddresses: builder.query<AddressType[], { includeExtraAddresses?: boolean; includeBlastingClients?: boolean }>({
      query: ({ includeExtraAddresses, includeBlastingClients } = {}) => {
        const data: any = {};
        if (includeExtraAddresses) {
          data.all_blasting_clients = true;
          data.attach_non_zeel_clients = true;
        }

        if (includeBlastingClients) {
          data.all_blasting_clients = true;
        }
        return {
          url: '/api/v1/member/addresses',
          method: 'GET',
          params: {
            _data: '',
            ...data,
          },
          validateStatus: (response, result) => response.status === 200 && !result.errors,
        };
      },
      transformResponse: (responseData: { addresses: SourceAddressType[] }) => {
        const batched = [];
        const mappedAddresses = (responseData.addresses || []).map((a) =>
          AddressMapping.apply(a, (_a) => batched.push(_a))
        );

        return mappedAddresses;
      },
      providesTags: (result) => {
        return result ? [...result.map(({ id }) => ({ type: TAGS.ADDRESS, id })), TAGS.ADDRESS] : [TAGS.ADDRESS];
      },
    }),

    /**
     * Get user's shipping addresses
     */
    getShippingAddresses: builder.query<
      AddressType[],
      { includeExtraAddresses?: boolean; includeBlastingClients?: boolean }
    >({
      query: ({ includeExtraAddresses, includeBlastingClients } = {}) => {
        const data: any = {};
        if (includeExtraAddresses) {
          data.all_blasting_clients = true;
          data.attach_non_zeel_clients = true;
        }

        data.ignore_shipping_filter = true;

        if (includeBlastingClients) {
          data.all_blasting_clients = true;
        }
        return {
          url: '/api/v1/member/addresses',
          method: 'GET',
          params: {
            _data: '',
            ...data,
          },
          validateStatus: (response, result) => response.status === 200 && !result.errors,
        };
      },
      transformResponse: (responseData: { addresses: SourceAddressType[] }) => {
        const batched = [];
        const mappedShippingAddresses = (responseData.addresses || [])
          .map((a) => AddressMapping.apply(a, (_a) => batched.push(_a)))
          ?.filter((a) => a.shipping);
        return mappedShippingAddresses;
      },
      providesTags: (result) => {
        return result ? [...result.map(({ id }) => ({ type: TAGS.ADDRESS, id })), TAGS.ADDRESS] : [TAGS.ADDRESS];
      },
    }),

    /**
     * Get address by id
     */
    getAddress: builder.query<AddressType, string>({
      query: (id) => {
        const data: any = {};

        return {
          url: `/api/v1/member/addresses/${id}`,
          method: 'GET',
          params: {
            _data: '',
            ...data,
          },
          validateStatus: (response, result) => response.status === 200 && !result.errors,
        };
      },
      transformResponse: (responseData: { address: SourceAddressType }) => {
        return AddressMapping.apply(responseData.address);
      },
      providesTags: (result) => {
        return result ? [{ type: TAGS.ADDRESS, id: result.id }, TAGS.ADDRESS] : [TAGS.ADDRESS];
      },
    }),

    /**
     * Create address
     */
    createAddress: builder.mutation<AddressType, Partial<AddressType> & { categoryKey?: string }>({
      queryFn: async (_arg, _queryApi, _extraOptions, fetchWithBQ) => {
        const addressData = { ..._arg };

        if (!addressData.addressCategoryId) {
          if (addressData.shipping) addressData.addressCategoryId = AddressCategory.Shipping;
        }
        if (addressData.floor) addressData.walkup = true;

        if (addressData.categoryKey) {
          addressData.addressCategory = addressData.categoryKey;
          delete addressData.addressCategoryId;
          delete addressData.categoryKey;
        }

        const sourceData: SourceAddressType = AddressMapping.reverse(addressData as AddressType);

        const result = await fetchWithBQ({
          url: '/api/v1/member/addresses/create',
          method: 'POST',
          body: {
            _data: '',
            ...sourceData,
          },
          validateStatus: (response, _result) => response.status === 200 && !_result.errors,
        });
        if (result.error) return { error: result.error };

        // Fetch address (since api only returns id)
        const fetchAddressResult = await fetchWithBQ({
          url: `/api/v1/member/addresses/${result.data?.id}`,
          method: 'GET',
          params: {
            _data: '',
          },
          validateStatus: (response, _result) => response.status === 200 && !_result.errors,
        });

        const fullAddress = fetchAddressResult.data?.address;

        return fullAddress
          ? {
              data: AddressMapping.apply(fullAddress),
            }
          : { error: fetchAddressResult.error };
      },
      invalidatesTags: () => [TAGS.ADDRESS],
    }),

    /**
     * Update address
     */
    updateAddress: builder.mutation<
      AddressType,
      Partial<AddressType> & { categoryKey?: string } & Pick<AddressType, 'id'>
    >({
      queryFn: async (_arg, _queryApi, _extraOptions, fetchWithBQ) => {
        const addressData = { ..._arg };

        if (!addressData.addressCategoryId) {
          if (addressData.shipping) addressData.addressCategoryId = AddressCategory.Shipping;
        }

        if (addressData.categoryKey) {
          addressData.addressCategory = addressData.categoryKey;
          delete addressData.addressCategoryId;
          delete addressData.categoryKey;
        }

        const sourceData: SourceAddressType = AddressMapping.reverse(addressData as AddressType);

        const result = await fetchWithBQ({
          url: `/api/v1/member/addresses/update/${addressData.id}`,
          method: 'POST',
          body: {
            _data: '',
            ...sourceData,
          },
          validateStatus: (response, _result) => response.status === 200 && !_result.errors,
        });
        if (result.error) return { error: result.error };

        // Fetch address (since api only returns id)
        const fetchAddressResult = await fetchWithBQ({
          url: `/api/v1/member/addresses/${_arg.id}`,
          method: 'GET',
          params: {
            _data: '',
          },
          validateStatus: (response, _result) => response.status === 200 && !_result.errors,
        });

        const fullAddress = fetchAddressResult.data?.address;

        return fullAddress
          ? {
              data: AddressMapping.apply(fullAddress),
            }
          : { error: fetchAddressResult.error };
      },
      invalidatesTags: (result, error, arg) => [TAGS.ADDRESS, { type: TAGS.ADDRESS, id: arg.id }],
    }),

    /**
     * Delete an existing address
     */
    deleteAddress: builder.mutation<GenericResponse, string>({
      query: (id) => {
        return {
          url: `/api/v1/member/addresses/delete/${id}`,
          method: 'POST',
          body: {
            _data: '',
          },
          validateStatus: (response, result) => response.status === 200 && !result.errors,
        };
      },
      invalidatesTags: (result, error, arg) => [TAGS.ADDRESS, { type: TAGS.ADDRESS, id: arg }],
    }),

    /**
     * Make an existing address as default
     */
    setAddressAsDefault: builder.mutation<GenericResponse, string>({
      query: (id) => {
        return {
          url: `/api/v1/member/addresses/main/${id}`,
          method: 'POST',
          body: {
            _data: '',
          },
          validateStatus: (response, result) => response.status === 200 && !result.errors,
        };
      },
      invalidatesTags: (result, error, arg) => [TAGS.ADDRESS, { type: TAGS.ADDRESS, id: arg }],
    }),

    /**
     * Get user referral information
     */
    getInviteReferralDetails: builder.query<
      {
        title: string;
        subtitle: string;
        description: string;
        code: string;
        termsAndConditions: string;
        links: { facebook: string; twitter: string; mail: string; invite: string };
      },
      void
    >({
      query: () => {
        return {
          url: `/api/v1/referrals/invite`,
          method: 'GET',
          params: {
            _data: '',
          },
          validateStatus: (response, result) => response.status === 200 && !result.errors,
        };
      },
      transformResponse: (responseData: {
        title: string;
        sub_title: string;
        description: string;
        code: string;
        terms_and_conditions?: any;
        links?: {
          facebook?: string;
          twitter?: string;
          email?: {
            subject?: string;
            body?: string;
          };
          web?: string;
        };
      }) => {
        return {
          title: responseData.title,
          subtitle: responseData.sub_title,
          description: responseData.description,
          code: responseData.code,
          termsAndConditions: responseData.terms_and_conditions ? responseData.terms_and_conditions.text : '',
          links: {
            facebook: responseData.links?.facebook || '',
            twitter: responseData.links?.twitter || '',
            mail: responseData.links?.email
              ? `mailto:?subject=${responseData.links.email.subject}&body=${responseData.links.email.body}`
              : '',
            invite: responseData.links?.web || '',
          },
        };
      },
    }),
    /**
     * Get available memberships for the user for a specific zip
     */
    getAvailablePlans: builder.query<{ [key: string]: PlanType }, string>({
      query: (zipCode) => {
        return {
          url: `/api/v1/membership/availability`,
          method: 'GET',
          params: {
            _data: '',
            zip: zipCode,
          },
          validateStatus: (response, result) => response.status === 200 && !result.errors,
        };
      },
      transformResponse: (responseData: {
        plans: {
          [key: string]: SourcePlanType;
        };
      }) => {
        const mappedPlans: { [key: string]: PlanType } = {};
        Object.entries(responseData?.plans || {}).forEach(
          ([key, plan]) => (mappedPlans[key] = PlanMapping.apply(plan))
        );
        return mappedPlans;
      },
    }),

    /**
     * Get membership pricing and details
     */
    getMembershipPricing: builder.query<
      {
        subTotal: { label: string; price: number };
        tip: { label: string; price: number };
        tax: { label: string; price: number };
        credits: { label: string; price: number; marketing: number; purchased: number };
        today: { label: string; price: number };
        monthly: { label: string; price: number };
      },
      { zipCode: string; planId?: string }
    >({
      query: ({ zipCode, planId }) => {
        return {
          url: '/api/v1/membership/checkout_summary',
          method: 'GET',
          params: {
            _data: '',
            zip: zipCode,
            plan_id: planId || undefined,
          },
          validateStatus: (response, result) => response.status === 200 && !result.errors,
        };
      },
      transformResponse: (responseData: { summary: any }) => {
        const summary = responseData?.summary || {};
        return {
          subTotal: {
            label: summary[0]?.label,
            price: summary[0]?.prc,
          },
          tip: {
            label: summary[1]?.label,
            price: summary[1]?.prc,
          },
          tax: {
            label: summary[2] ? summary[2]?.label : '0% Tax',
            price: summary[2] ? summary[2]?.prc : 0,
          },
          credits: summary[3]
            ? {
                label: summary[3]?.label,
                price: summary[3]?.prc,
                marketing: summary[3]?.prc,
                purchased: 0,
              }
            : null,
          today: {
            label: summary[4]?.label,
            price: summary[4]?.prc,
          },
          monthly: {
            label: summary[5]?.label,
            price: summary[5]?.prc,
          },
        };
      },
    }),

    /**
     * Get membership agreement for user
     */
    getMembershipAgreement: builder.query<any, { planId: string; memberId: string }>({
      query: ({ planId, memberId }) => {
        return {
          url: '/api/v1/membership/contract',
          method: 'GET',
          params: {
            _data: '',
            plan_id: planId,
            member_id: memberId,
          },
          validateStatus: (response, result) => response.status === 200 && !result.errors,
        };
      },
      transformResponse: (responseData: { terms: string }) => {
        return responseData?.terms;
      },
    }),

    /**
     * Purchases or upgrades the membership of the user
     */
    purchaseMembership: builder.mutation<GenericResponse, MembershipPayloadType>({
      queryFn: async (_arg, _queryApi, _extraOptions, fetchWithBQ) => {
        const braintreeTokenResult = await fetchWithBQ({
          url: `/api/v1/member/card/client_token`,
          method: 'GET',
          params: {
            _data: '',
            sdk_version: 3,
          },
          validateStatus: (response, result) => response.status === 200 && !result.errors,
        });
        const braintreeToken = braintreeTokenResult?.data?.client_token;
        const deviceData = await helpers.getDeviceData(braintreeToken);

        const purchaseResult = await fetchWithBQ({
          url: `/api/v1/membership/${_arg.chosen_plan ? 'create' : 'upgrade'}`,
          method: 'POST',
          body: {
            _data: '',
            ..._arg,
            ...(deviceData ? { device_data: deviceData } : {}),
          },
          validateStatus: (response, result) => response.status === 200 && !result.errors,
        });
        if (purchaseResult?.error) return { error: purchaseResult.error };

        return {
          data: purchaseResult.data,
        };
      },
      invalidatesTags: [TAGS.USER],
    }),

    /**
     * Get list of all active\cancelled memberships, both personal and gifted
     */
    getAllMemberships: builder.query<
      {
        personalMemberships: MembershipType[];
        latestPersonalMembership: MembershipType | null;
        giftedMemberships: GiftedMembershipType[];
      },
      void
    >({
      query: () => {
        return {
          url: `/api/v1/membership/fetch_all`,
          method: 'POST',
          params: {
            _data: '',
          },
          validateStatus: (response, result) => response.status === 200 && !result.errors,
        };
      },
      transformResponse: (responseData: {
        active_personal_memberships: SourceMembershipType[];
        cancelled_personal_memberships: SourceMembershipType[];
        active_gifted_memberships: SourceGiftedMembershipType[];
        cancelled_gifted_memberships: SourceGiftedMembershipType[];
      }) => {
        const memberships: {
          personalMemberships: MembershipType[];
          latestPersonalMembership: MembershipType | null;
          giftedMemberships: GiftedMembershipType[];
        } = {
          personalMemberships: [
            ...(responseData.active_personal_memberships || []),
            ...(responseData.cancelled_personal_memberships || []),
          ].map((m) => MembershipMapping.apply(m)),
          latestPersonalMembership: null,
          giftedMemberships: [
            ...(responseData.active_gifted_memberships || []),
            ...(responseData.cancelled_gifted_memberships || []),
          ].map((m) => GiftedMembershipMapping.apply(m)),
        };

        if (memberships.personalMemberships?.length) {
          const active = memberships.personalMemberships.find((m) => !m.cancelled);
          const allCancelled = memberships.personalMemberships.filter((m) => m.cancelled);
          const latestCancelled = allCancelled.length ? allCancelled[allCancelled.length - 1] : null;
          if (active || latestCancelled) {
            memberships.latestPersonalMembership = active || latestCancelled;
          }
        }
        return memberships;
      },
      providesTags: () => {
        return [TAGS.USER_MEMBERSHIP];
      },
    }),

    /**
     * Turns ON/OFF an upcoming membership charge
     */
    toggleUpcomingMembershipCharge: builder.mutation<undefined, { id: string; action: 'activate' | 'deactivate' }>({
      query: ({ id, action = 'activate' }) => {
        return {
          url: `/api/v1/membership/${action === 'activate' ? 'activate_charge' : 'cancel_charge'}/${id}`,
          method: 'POST',
          params: {
            _data: '',
          },
          validateStatus: (response, result) => response.status === 200 && !result.errors,
        };
      },
      invalidatesTags: [TAGS.USER_MEMBERSHIP],
    }),

    /**
     * Change a membership's payment method
     */
    changeMembershipPaymentMethod: builder.mutation<undefined, { planId: string; paymentMethodId: string }>({
      query: ({ planId, paymentMethodId }) => {
        return {
          url: `/api/v1/membership/set_payment_profile`,
          method: 'POST',
          params: {
            membership_id: planId,
            payment_profile_id: paymentMethodId,
            _data: '',
          },
          validateStatus: (response, result) => response.status === 200 && !result.errors,
        };
      },
      invalidatesTags: [TAGS.USER, TAGS.USER_MEMBERSHIP],
    }),

    /**
     * Retrieves the cost of cancelling a membership (to be displayed via a checkout-like UI)
     */
    getMembershipCancellationPricingSummary: builder.query<
      {
        cancellationFee: number;
        appliedCredit: number;
        taxAmount: number;
        chargeAmount: number;
      },
      string
    >({
      query: (planId) => {
        return {
          url: `/api/v1/membership/cancellation_information`,
          method: 'POST',
          params: {
            membership_id: planId,
            _data: '',
          },
          validateStatus: (response, result) => response.status === 200 && !result.errors,
        };
      },
      transformResponse: (responseData: { charge_info: any }) => {
        const chargeInfo = responseData?.charge_info || {};
        return {
          cancellationFee: chargeInfo.cancellation_fee,
          appliedCredit: chargeInfo.applied_credit,
          taxAmount: chargeInfo.tax_amount,
          chargeAmount: chargeInfo.charge_amount,
        };
      },
    }),

    /**
     * Cancels a membership plan by id
     */
    cancelMembership: builder.mutation<undefined, { planId: string; cancellationReasonId: number }>({
      query: ({ planId, cancellationReasonId }) => {
        return {
          url: `/api/v1/membership/cancel`,
          method: 'POST',
          params: {
            membership_id: planId,
            cancellation_reason_id: cancellationReasonId,
            _data: '',
          },
          validateStatus: (response, result) => response.status === 200 && !result.errors,
        };
      },
      invalidatesTags: [TAGS.USER, TAGS.USER_MEMBERSHIP],
    }),

    /**
     * Update user's guest
     */
    updateGuest: builder.mutation<GenericResponse, Partial<PersonType> & Pick<PersonType, 'id'>>({
      query: (guestData) => {
        const sourceData = omitBy(PersonMapping.reverse(guestData), isNil);
        if (!sourceData.nickname && guestData.firstName && guestData.lastName) {
          sourceData.nickname = `${guestData.firstName} ${guestData.lastName}`;
        }

        return {
          url: `/api/v1/member/persons/${guestData.id}/update`,
          method: 'POST',
          body: {
            _data: 'persons',
            ...sourceData,
          },
          validateStatus: (response, result) => response.status === 200 && !result.errors,
        };
      },
      invalidatesTags: [TAGS.USER],
    }),

    /**
     * Adds a new guest to the user's account
     */
    createGuest: builder.mutation<string, PersonType>({
      query: (guestData) => {
        const sourceData = PersonMapping.reverse(guestData);
        return {
          url: '/api/v1/member/persons/create',
          method: 'POST',
          body: {
            _data: 'persons',
            ...sourceData,
          },
          validateStatus: (response, result) => response.status === 200 && !result.errors,
        };
      },
      transformResponse: (responseData: { id: string }) => {
        return responseData?.id + '';
      },
      invalidatesTags: [TAGS.USER],
    }),

    /**
     * Deletes a guest from the user's account
     */
    deleteGuest: builder.mutation<GenericResponse, string>({
      query: (id) => {
        return {
          url: `/api/v1/member/persons/${id}/delete`,
          method: 'POST',
          body: {
            _data: '',
          },
          validateStatus: (response, result) => response.status === 200 && !result.errors,
        };
      },
      invalidatesTags: [TAGS.USER],
    }),

    /**
     * Get gift certificates occasions and amounts
     */
    getGiftCertificateOptions: builder.query<GiftCertificateOptionsType, void>({
      queryFn: async (_arg, _queryApi, _extraOptions, fetchWithBQ) => {
        const occasionsResult = await fetchWithBQ({
          url: '/api/v1/giftcards/occasions',
          method: 'GET',
          validateStatus: (response, result) => response.status === 200 && !result.errors,
        });
        if (occasionsResult.error) return { error: occasionsResult.error };

        const amountsResult = await fetchWithBQ({
          url: '/api/v1/discountcode/gifts',
          method: 'GET',
          params: { by_location_group: 1, add_price_list: 1 },
          validateStatus: (response, result) => response.status === 200 && !result.errors,
        });
        if (amountsResult.error) return { error: amountsResult.error };

        return {
          data: {
            occasions: occasionsResult?.data?.occasions || [],
            amounts: amountsResult?.data,
          },
        };
      },
    }),

    /**
     * Purchase a gift certificate
     */
    purchaseGiftCertificate: builder.mutation<string, GiftPayloadType>({
      queryFn: async (giftPayload, _queryApi, _extraOptions, fetchWithBQ) => {
        const braintreeTokenResult = await fetchWithBQ({
          url: `/api/v1/member/card/client_token`,
          method: 'GET',
          params: {
            _data: '',
            sdk_version: 3,
          },
          validateStatus: (response, result) => response.status === 200 && !result.errors,
        });
        const braintreeToken = braintreeTokenResult?.data?.client_token;
        const deviceData = await helpers.getDeviceData(braintreeToken);

        const purchaseResult = await fetchWithBQ({
          url: '/api/v1/discountcode/create/gift_card',
          method: 'POST',
          body: {
            _data: '',
            ...giftPayload,
            ...(deviceData ? { device_data: deviceData } : {}),
          },
          validateStatus: (response, result) => response.status === 200 && !result.errors,
        });
        if (purchaseResult?.error) return { error: purchaseResult.error };

        return {
          data: purchaseResult.data?.tx_id as string,
        };
      },
    }),

    /**
     * Redeem promo code (16)
     */
    redeemPromo: builder.mutation<GenericResponse, { nonce: string; amount: number }>({
      query: ({ nonce, amount }) => {
        return {
          url: '/api/v1/member/card/create',
          method: 'POST',
          body: {
            _data: '',
            payment_method_nonce: nonce,
            blackhawk_chargeable_amount: amount,
          },
          validateStatus: (response, result) => response.status === 200 && !result.errors,
        };
      },
    }),

    /**
     * Get promo issuer
     */
    getPromoIssuer: builder.query<'promo19' | 'promo16' | 'gift', string>({
      query: (code) => {
        return {
          url: '/api/v1/card/get_issuer',
          method: 'GET',
          params: {
            _data: '',
            number: code,
          },
          validateStatus: (response, result) => response.status === 200 && !result.errors,
        };
      },
      transformResponse: (responseData: { issuer: 'promo19' | 'promo16' | 'gift' }) => {
        return {
          spafinder_v1: 'promo19',
          blackhawk_visa: 'promo16',
          zeel_gift_card: 'gift',
        }[responseData?.issuer];
      },
    }),

    /**
     * Log progress of various flows using a category
     */
    logUserProgress: builder.mutation<
      GenericResponse,
      { category: string; gender?: GenderEnum; firstName?: string; lastName?: string; birthdate?: string }
    >({
      query: ({ category, gender, firstName, lastName, birthdate }) => {
        return {
          url: `/api/v1/member/log/${category}`,
          method: 'POST',
          body: {
            _data: '',
            sex: gender,
            fname: firstName,
            lname: lastName,
            birthday: birthdate,
          },
          validateStatus: (response, result) => response.status === 200 && !result.errors,
        };
      },
    }),

    /**
     * Get credit summary
     */
    getCreditSummary: builder.query<{ total: number }, void>({
      query: () => {
        return {
          url: '/api/v1/member/credits/get',
          method: 'POST',
          params: {
            _data: '',
          },
          validateStatus: (response, result) => response.status === 200 && !result.errors,
        };
      },
      transformResponse: (responseData: { credits: { total: number } }) => {
        return { total: responseData.credits.total };
      },
      providesTags: [TAGS.CREDIT],
    }),

    /**
     * Add promo code
     */
    addPromoCode: builder.mutation<null, string>({
      query: (promotionCode) => {
        return {
          url: '/api/v1/discountcode/redeem',
          method: 'POST',
          body: {
            promotion_code: promotionCode,
          },
          validateStatus: (response, result) => response.status === 200 && !result.errors,
        };
      },
      invalidatesTags: (result) => (result ? [TAGS.CREDIT] : []),
    }),

    /**
     * Get credit history
     */
    getCreditHistory: builder.query<CreditHistoryType[], void>({
      query: () => {
        return {
          url: '/api/v1/member/credits/history',
          method: 'POST',
          params: {
            _data: '',
          },
          validateStatus: (response, result) => response.status === 200 && !result.errors,
        };
      },
      transformResponse: (responseData: { credits_history: SourceCreditHistoryType[] }) => {
        return responseData.credits_history.map((history) => CreditHistoryMapping.apply(history));
      },
      providesTags: [TAGS.CREDIT],
    }),
  }),
});

export const {
  // Queries
  useGetMeQuery,
  useGetTokenDetailsQuery,
  useGetAccountTypesQuery,
  useGetJumioIframeUrlQuery,
  useGetClientBenefitsEligibilityQuery,
  useGetAddressesQuery,
  useGetShippingAddressesQuery,
  useGetAddressQuery,
  useGetInviteReferralDetailsQuery,
  useGetAvailablePlansQuery,
  useGetMembershipAgreementQuery,
  useGetMembershipPricingQuery,
  useGetGiftCertificateOptionsQuery,
  useGetPromoIssuerQuery,
  useGetCreditSummaryQuery,
  useGetCreditHistoryQuery,
  useGetAllMembershipsQuery,
  useGetMembershipCancellationPricingSummaryQuery,
  useGetAdditionalAttributesQuery,
  useGetKemtaiTokenQuery,
  useGetKemtaiActivitiesQuery,
  useGetKemtaiActivityResultsQuery,
  // Lazy
  useLazyGetMeQuery,
  useLazyGetAddressQuery,
  useLazyGetAvailablePlansQuery,
  useLazyGetMembershipAgreementQuery,
  useLazyGetMembershipPricingQuery,
  useLazyGetGiftCertificateOptionsQuery,
  useLazyGetPromoIssuerQuery,
  useLazyGetAccountTypesQuery,
  useLazyGetAddressesQuery,
  useLazyGetClientBenefitsEligibilityQuery,
  useLazyGetCreditHistoryQuery,
  useLazyGetCreditSummaryQuery,
  useLazyGetAllMembershipsQuery,
  useLazyGetInviteReferralDetailsQuery,
  useLazyGetJumioIframeUrlQuery,
  useLazyGetMembershipCancellationPricingSummaryQuery,
  useLazyGetShippingAddressesQuery,
  useLazyGetTokenDetailsQuery,
  useLazyGetAdditionalAttributesQuery,
  useLazyGetKemtaiTokenQuery,
  useLazyGetKemtaiActivitiesQuery,
  useLazyGetKemtaiActivityResultsQuery,
  // Mutations
  useSendMobilePhoneVerificationCodeMutation,
  useVerifyMobilePhoneMutation,
  useVerifyUserIdentityMutation,
  useLogUserProgressMutation,
  useUpdatePasswordFromTokenizedFlowMutation,
  useUpdateUserMutation,
  useUpdateUserEmailMutation,
  useUpdateUserPasswordMutation,
  useCreateGuestMutation,
  useUpdateGuestMutation,
  useDeleteGuestMutation,
  useCreateAddressMutation,
  useUpdateAddressMutation,
  useDeleteAddressMutation,
  useSetAddressAsDefaultMutation,
  useUpdateUserSignupDataMutation,
  usePurchaseGiftCertificateMutation,
  useRedeemPromoMutation,
  useSendResetPasswordMutation,
  usePurchaseMembershipMutation,
  useAddPromoCodeMutation,
  useCancelMembershipMutation,
  useToggleUpcomingMembershipChargeMutation,
  useChangeMembershipPaymentMethodMutation,
} = userApi;
