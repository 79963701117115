import * as D from 'io-ts/Decoder';

import { SourcePricingItemType } from '../booking';
import { BooleanLiteralDecoder, StringOrNumberDecoder } from '../types';

// Kemtai

export type KemtaiActivity = {
  id: string;
  assigned_at: string;
  assigned_by: string;
  start_date: string;
  start_delay: number | null;
  frequency: {
    id: string;
    interval: number | null;
    days_of_week: string[] | null;
    num_of_times_per_day: number;
    num_of_times_per_week: number;
    is_once: boolean;
  };
  started_at: string | null;
  content: {
    id: string;
    type: 'exercise-protocol' | 'rom-assessment';
  } & (
    | {
        type: 'exercise-protocol';
        name: string;
        duration: number;
        items: KemtaiExerciseProtocol[];
        created_by: string | null;
        created_at: string;
      }
    | {
        type: 'rom-assessment';
        name: string;
        duration: number;
        exercise_id?: string;
        exercise_name?: string;
        assets_url?: string;
        side?: 'original' | 'flipped';
      }
  );
  status: 'active' | 'pending' | 'completed' | 'terminated';
  completed_at: string | null;
  times_prescribed_today: number;
  times_completed_today: number;
  seen_at: string | null;
  terminated_at: string | null;
  num_of_completions_last_week: number;
};

export type KemtaiActivityResult = {
  id: string;
  created_at?: string;
  exercise_name: string;
  assets_url?: string;
  state: string | null;
  score: number;
  count: number | null;
  count_goal: number | null;
  duration: number;
  duration_goal: number;
  num_of_sets: number;
  side: 'original' | 'flipped';
  exercise_id: number;
  is_counting_exercise: boolean;
  is_motion_guided_exercise: boolean;
};

export type KemtaiExerciseProtocol = {
  id: string;
  exercise: number;
  count_goal: number | null;
  duration_goal: number;
  resting_time: number;
  num_of_sets: number;
  side_option: 'original' | 'flipped';
  exercise_name: string;
  assets_url: string;
  equipment: {
    id: number;
    name: string;
    icon: string;
  }[];
};

// Account Types
export const SourceAccountTypesDecoder = D.struct({
  is_admin: D.boolean,
  is_applied_concierge: D.boolean,
  is_concierge: D.boolean,
  is_full_member: D.boolean,
  is_hipaa: D.boolean,
  is_member: D.boolean,
  is_or_was_zeelot: D.boolean,
  is_provider: D.boolean,
  is_spa: D.boolean,
  is_spa_full_service: D.boolean,
  is_spa_self_service: D.boolean,
  is_temp_member: D.boolean,
  is_va: D.boolean,
});
export type SourceAccountTypesType = D.TypeOf<typeof SourceAccountTypesDecoder>;
export const AccountTypesDecoder = D.struct({
  isAdmin: D.boolean,
  isAppliedConcierge: D.boolean,
  isConcierge: D.boolean,
  isFullMember: D.boolean,
  isHipaa: D.boolean,
  isMember: D.boolean,
  isOrWasZeelot: D.boolean,
  isProvider: D.boolean,
  isSpa: D.boolean,
  isSpaFullService: D.boolean,
  isSpaSelfService: D.boolean,
  isTempMember: D.boolean,
  isVa: D.boolean,
});
export type AccountTypesType = D.TypeOf<typeof AccountTypesDecoder>;

// Business Flags
export const SourceAdditionalAttributesDecoder = D.struct({
  /* Member has active medical referral, meaning it's not yet completed and not expired */
  has_active_medical_referral: D.boolean,
  /* Member has booked at least one wellness appointment */
  has_wellness_history: D.boolean,
  /* Member has booked at least one medical massage appointment */
  has_medical_history: D.boolean,
  /* Member has ordered at least one physical product */
  has_store_history: D.boolean,
  /* Member is assigned to Kemtai patient record */
  is_assigned_to_kemtai: D.boolean,
});
export type SourceAdditionalAttributesType = D.TypeOf<typeof SourceAdditionalAttributesDecoder>;
export const AdditionalAttributesDecoder = D.struct({
  hasActiveMedicalReferral: D.boolean,
  hasWellnessHistory: D.boolean,
  hasMedicalHistory: D.boolean,
  hasStoreHistory: D.boolean,
  isAssignedToKemtai: D.boolean,
});
export type AdditionalAttributesType = D.TypeOf<typeof AdditionalAttributesDecoder>;

// Gender
export enum GenderEnum {
  Male = 'm',
  Female = 'f',
  NonBinary = 'nb',
}

export const GenderDecoder = D.literal(GenderEnum.Male, GenderEnum.Female, GenderEnum.NonBinary);
export type GenderType = D.TypeOf<typeof GenderDecoder>;

// Relationship
export const SourceRelationshipDecoder = D.literal(
  'Myself',
  'Spouse/Significant Other',
  'Parent',
  'Friend',
  'Boss/Co-Worker',
  'Other Relative',
  'Client',
  'Hotel Guest'
);
export type SourceRelationshipType = D.TypeOf<typeof SourceRelationshipDecoder>;
export const RelationshipDecoder = SourceRelationshipDecoder;
export type RelationshipType = D.TypeOf<typeof RelationshipDecoder>;

// Person
export const SourcePersonDecoder = D.partial({
  id: D.nullable(StringOrNumberDecoder),
  nickname: D.nullable(D.string),
  fname: D.nullable(D.string),
  lname: D.nullable(D.string),
  mobile: D.nullable(D.string),
  relationship: D.nullable(SourceRelationshipDecoder),
  gender: D.nullable(GenderDecoder),
  personal_notes: D.nullable(D.string),
  email: D.nullable(D.string),
  birthday: D.nullable(D.string),
  has_guardian: D.nullable(BooleanLiteralDecoder),
  legal_guardian_member_person_id: D.nullable(D.string),
});
export type SourcePersonType = D.TypeOf<typeof SourcePersonDecoder>;
export const PersonDecoder = D.partial({
  id: D.nullable(D.string),
  nickname: D.nullable(D.string),
  firstName: D.nullable(D.string),
  lastName: D.nullable(D.string),
  fullName: D.nullable(D.string),
  mobile: D.nullable(D.string),
  relationship: D.nullable(RelationshipDecoder),
  gender: D.nullable(GenderDecoder),
  personalNotes: D.nullable(D.string),
  email: D.nullable(D.string),
  birthdate: D.nullable(D.string),
  hasGuardian: D.nullable(D.boolean),
  legalGuardianMemberPersonId: D.nullable(D.string),
});
export type PersonType = D.TypeOf<typeof PersonDecoder>;

// User
export const SourceUserDecoder = D.partial({
  id: StringOrNumberDecoder,
  uid: D.string,
  fname: D.nullable(D.string),
  lname: D.nullable(D.string),
  email: D.nullable(D.string),
  phone: D.nullable(D.string),
  mobile: D.nullable(D.string),
  mobile_confirm: BooleanLiteralDecoder,
  zip: D.nullable(D.string),
  personal_notes: D.nullable(D.string),
  birthday: D.nullable(D.string),
  sex: D.nullable(GenderDecoder),
  current_terms_accepted: D.nullable(BooleanLiteralDecoder),
  persons: D.array(SourcePersonDecoder),
  completed_blasting_appointment_count: D.number,
  identity_verified: BooleanLiteralDecoder,
  plan_name: D.nullable(D.string),
  allow_upgrade: D.boolean,
});
export type SourceUserType = D.TypeOf<typeof SourceUserDecoder>;
export const UserDecoder = D.partial({
  id: D.string,
  uid: D.string,
  firstName: D.nullable(D.string),
  lastName: D.nullable(D.string),
  fullName: D.nullable(D.string),
  email: D.nullable(D.string),
  phone: D.nullable(D.string),
  mobile: D.nullable(D.string),
  mobileConfirmed: D.boolean,
  zipCode: D.nullable(D.string),
  personalNotes: D.nullable(D.string),
  birthdate: D.nullable(D.string),
  gender: D.nullable(GenderDecoder),
  currentTermsAccepted: D.boolean,
  people: D.array(PersonDecoder),
  completedAppointmentCount: D.number,
  verified: D.boolean,
  membership: D.nullable(D.string),
  membershipUpgradable: D.boolean,
});
export type UserType = D.TypeOf<typeof UserDecoder>;

// Address
export const SourceAddressClientDecoder = D.partial({
  id: StringOrNumberDecoder,
  logo: D.nullable(D.string),
  logo_sm: D.nullable(D.string),
  name: D.nullable(D.string),
});
export type SourceAddressClientType = D.TypeOf<typeof SourceAddressClientDecoder>;
export const AddressClientDecoder = D.partial({
  id: StringOrNumberDecoder,
  logo: D.nullable(D.string),
  logo_sm: D.nullable(D.string),
  name: D.nullable(D.string),
});

export type AddressClientType = D.TypeOf<typeof AddressClientDecoder>;

export const SourceAddressDecoder = D.partial({
  id: StringOrNumberDecoder,
  name: D.nullable(D.string),
  description: D.nullable(D.string),
  shipping: D.nullable(BooleanLiteralDecoder),
  main: D.nullable(BooleanLiteralDecoder),
  address_category: D.nullable(D.string),
  address_category_id: D.nullable(StringOrNumberDecoder),
  status: D.nullable(D.string),
  address1: D.nullable(D.string),
  address2: D.nullable(D.string),
  city: D.nullable(D.string),
  state: D.nullable(D.string),
  country: D.nullable(D.string),
  zip: D.nullable(D.string),
  latitude: D.nullable(D.string),
  longitude: D.nullable(D.string),
  approximate: D.nullable(BooleanLiteralDecoder),
  walkup: D.nullable(BooleanLiteralDecoder),
  floor: D.nullable(StringOrNumberDecoder),
  table_count: D.nullable(D.string),
  ein: D.nullable(D.string),
  dress_code: D.nullable(D.string),
  check_in: D.nullable(D.string),
  check_out: D.nullable(D.string),
  client: D.nullable(SourceAddressClientDecoder),
  client_id: D.nullable(D.string),
  pet_text: D.nullable(D.string),
  pets_text: D.nullable(D.string),
  notes: D.nullable(D.string),
  building_notes: D.nullable(D.string),
  parking: D.nullable(D.string),
});
export type SourceAddressType = D.TypeOf<typeof SourceAddressDecoder>;
export const AddressDecoder = D.partial({
  id: D.string,
  name: D.nullable(D.string),
  description: D.nullable(D.string),
  shipping: D.boolean,
  default: D.boolean,
  addressCategory: D.nullable(D.string),
  addressCategoryId: D.nullable(D.string),
  status: D.nullable(D.string),
  address1: D.nullable(D.string),
  address2: D.nullable(D.string),
  city: D.nullable(D.string),
  state: D.nullable(D.string),
  country: D.nullable(D.string),
  zipCode: D.nullable(D.string),
  latitude: D.nullable(D.string),
  longitude: D.nullable(D.string),
  approximate: D.boolean,
  walkup: D.nullable(D.boolean),
  floor: D.nullable(D.number),
  tableCount: D.nullable(D.number),
  ein: D.nullable(D.string),
  dressCode: D.nullable(D.string),
  checkIn: D.nullable(D.string),
  checkOut: D.nullable(D.string),
  client: D.nullable(AddressClientDecoder),
  clientId: D.nullable(D.string),
  petText: D.nullable(D.string),
  petsText: D.nullable(D.string),
  notes: D.nullable(D.string),
  buildingNotes: D.nullable(D.string),
  parking: D.nullable(D.string),
});
export type AddressType = D.TypeOf<typeof AddressDecoder>;

// Client Benefits Eligibility
export const SourceClientBenefitsEligibilityDecoder = D.struct({
  copay_amount: D.nullable(D.string),
  credits: D.nullable(D.number),
  logo: D.nullable(D.string),
  name: D.nullable(D.string),
});
export type SourceClientBenefitsEligibilityType = D.TypeOf<typeof SourceClientBenefitsEligibilityDecoder>;
export const ClientBenefitsEligibilityDecoder = D.struct({
  copayAmount: D.nullable(D.string),
  credits: D.nullable(D.number),
  logo: D.nullable(D.string),
  name: D.nullable(D.string),
});
export type ClientBenefitsEligibilityType = D.TypeOf<typeof ClientBenefitsEligibilityDecoder>;

// Membership Plans
export const SourcePlanDecoder = D.partial({
  title: D.string,
  item_price: D.number,
  features: D.array(
    D.partial({
      type: D.string,
      title: D.string,
      description: D.string,
    })
  ),
  features_short: D.array(D.string),
  tip: D.string,
  tip_percentage: D.number,
  tax: D.string,
  tax_percentage: D.number,
  transaction_amount_without_credit: D.string,
  savings: D.partial({
    percentage: D.string,
  }),
  shipping_addresses_requirement: D.literal('required', 'optional'),
});
export type SourcePlanType = D.TypeOf<typeof SourcePlanDecoder>;
export const PlanDecoder = D.partial({
  title: D.string,
  price: D.number,
  priceWithoutCredit: D.string,
  features: D.array(
    D.partial({
      type: D.string,
      title: D.string,
      description: D.string,
    })
  ),
  featuresShort: D.array(D.string),
  tip: D.string,
  tipPercentage: D.number,
  tax: D.string,
  taxPercentage: D.number,
  savings: D.partial({
    percentage: D.string,
  }),
  shippingAddressRequired: D.boolean,
});
export type PlanType = D.TypeOf<typeof PlanDecoder>;

export const MembershipPayloadDecoder = D.partial({
  member_address_id: D.string,
  chosen_plan: D.string,
  signature: D.boolean,
  shipping_address_id: D.string,
  payment_method_nonce: D.string,
  payment_method_type: D.string,
  payment_profile_id: D.string,
});
export type MembershipPayloadType = D.TypeOf<typeof MembershipPayloadDecoder>;

export const GiftPayloadDecoder = D.partial({
  message: D.string,
  email: D.string,
  from_name: D.string,
  to_name: D.string,
  to_email: D.string,
  dispatch_mode: D.literal('now', 'select_date'),
  dispatch_date: D.string,
  download_pdf: D.boolean,
  giftcard_design_id: D.string,
  dont_use_credit: D.boolean,
  has_table: D.boolean,
  signature: D.boolean,
  product_id: D.string,
  amount: D.number,
  payment_method_nonce: D.string,
  payment_method_type: D.string,
  payment_profile_id: D.string,
});
export type GiftPayloadType = D.TypeOf<typeof GiftPayloadDecoder>;

// TODO - add decoder
export type GiftCertificateOptionsType = {
  occasions: {
    id: string;
    description: string;
    meta_description: string;
    meta_title: string;
    name: string;
    sub_title: string;
    title: string;
    url: string;
    gift_card_occasion_designs: {
      id: string;
      gift_card_occasion_id: string;
      image_url: string;
      name: string;
    }[];
    gift_card_occasion_details: {
      id: string;
      gift_card_occasion_id: string;
      title: string;
      description: string;
      detail_type_name: string;
      image_category_url: string;
    }[];
  }[];
  amounts: {
    gifts: {
      id: number;
      name: string;
      children?: {
        id: number;
        name: string;
        parent_id: number;
        categories: {
          id: number;
          name: string;
          products: {
            id: number;
            payment_info_required: boolean;
            total: SourcePricingItemType & {
              shipping_address_needed?: boolean;
              is_one_time_zeelot_gift?: boolean;
              is_zeelot_gift?: boolean;
            };
            base: SourcePricingItemType & {
              shipping_address_needed?: boolean;
              is_one_time_zeelot_gift?: boolean;
              is_zeelot_gift?: boolean;
            };
            credit: SourcePricingItemType & {
              shipping_address_needed?: boolean;
              is_one_time_zeelot_gift?: boolean;
              is_zeelot_gift?: boolean;
            };
            list: (SourcePricingItemType & {
              shipping_address_needed?: boolean;
              is_one_time_zeelot_gift?: boolean;
              is_zeelot_gift?: boolean;
            })[];
            pricing_without_credit?: any;
          }[];
        }[];
      }[];
    }[];
    total_credit_amount: string;
    total_marketing_credit_amount: string;
    total_purchased_credit_amount: string;
  };
};

export const SourceCreditHistoryDecoder = D.partial({
  id: StringOrNumberDecoder,
  reward: D.number,
  details: D.string,
  dt_inserted: D.string,
  dt_expires: D.string,
});

export type SourceCreditHistoryType = D.TypeOf<typeof SourceCreditHistoryDecoder>;

export const CreditHistoryDecoder = D.partial({
  id: D.string,
  reward: D.number,
  details: D.string,
  dateInserted: D.string,
  dateExpires: D.string,
});

export type CreditHistoryType = D.TypeOf<typeof CreditHistoryDecoder>;

export const SourceSubscriptionChargeTransactionDecoder = D.partial({
  id: StringOrNumberDecoder,
  member_payment_profiles_id: StringOrNumberDecoder,
  dt_tx: D.nullable(D.string),
  dt_refunded: D.nullable(D.string),
  dt_voided: D.nullable(D.string),
  charged: D.boolean,
  amount: D.number,
  zeel_promo_credit: D.number,
  standard_credit: D.number,
  client_credit: D.number,
  purchased_credit: D.number,
  credit: D.number,
  member_payment_profile: D.partial({
    id: StringOrNumberDecoder,
    profile_name: D.string,
    abbreviation: D.string,
    last_four: D.string,
    expiration_month: D.number,
    expiration_year: D.number,
  }),
});
export type SourceSubscriptionChargeTransactionType = D.TypeOf<typeof SourceSubscriptionChargeTransactionDecoder>;
export const SubscriptionChargeTransactionDecoder = D.partial({
  id: D.string,
  memberPaymentProfileId: D.string,
  dateTransaction: D.nullable(D.string),
  dateRefunded: D.nullable(D.string),
  dateVoided: D.nullable(D.string),
  charged: D.boolean,
  amount: D.number,
  zeelPromoCredit: D.number,
  standardCredit: D.number,
  clientCredit: D.number,
  purchasedCredit: D.number,
  credit: D.number,
  memberPaymentProfile: D.partial({
    id: StringOrNumberDecoder,
    profile_name: D.string,
    abbreviation: D.string,
    last_four: D.string,
    expiration_month: D.number,
    expiration_year: D.number,
  }),
});
export type SubscriptionChargeTransactionType = D.TypeOf<typeof SubscriptionChargeTransactionDecoder>;

export const SourceSubscriptionChargeDecoder = D.partial({
  id: D.nullable(StringOrNumberDecoder),
  tx_id: D.nullable(StringOrNumberDecoder),
  dt_planned: D.nullable(D.string),
  dt_actual: D.nullable(D.string),
  dt_inactive: D.nullable(D.string),
  member_contract_charge_cancellation_reason_id: D.nullable(D.number),
  cancellation_reason: D.nullable(D.string),
  required: D.nullable(D.boolean),
  tx: D.nullable(SourceSubscriptionChargeTransactionDecoder),
});
export type SourceSubscriptionChargeType = D.TypeOf<typeof SourceSubscriptionChargeDecoder>;
export const SubscriptionChargeDecoder = D.partial({
  id: D.nullable(D.string),
  transactionId: D.nullable(D.string),
  datePlanned: D.nullable(D.string),
  dateActual: D.nullable(D.string),
  dateInactive: D.nullable(D.string),
  cancellationReasonId: D.nullable(D.number),
  cancellationReason: D.nullable(D.string),
  required: D.nullable(D.boolean),
  transaction: D.nullable(SubscriptionChargeTransactionDecoder),
  // --- derived ---
  activated: D.boolean,
});
export type SubscriptionChargeType = D.TypeOf<typeof SubscriptionChargeDecoder>;

/**
 * Personal Memberships
 */
export const SourceMembershipDecoder = D.partial({
  id: StringOrNumberDecoder,
  type: D.string,
  dt_accepted: D.string,
  dt_expires: D.nullable(D.string),
  dt_cancel: D.nullable(D.string),
  cancellation_reason_id: D.nullable(D.string),
  cancellation_reason_label: D.nullable(D.string),
  day_of_month_charge: D.string,
  price: D.number,
  role: D.literal('gifter', 'standard'),
  num_upcomming_subscription_charges: D.number,
  num_past_subscription_charges: D.number,
  num_required_charges: D.number,
  completed_contract: D.boolean,
  is_self_redeemed_gift: D.boolean,
  is_gift: D.boolean,
  gift_from_fname: D.nullable(D.string),
  gift_from_lname: D.nullable(D.string),
  payment_profile: D.nullable(
    D.array(
      D.partial({
        id: StringOrNumberDecoder,
        profile_name: D.string,
        abbreviation: D.string,
        last_four: D.string,
        expiration_month: D.number,
        expiration_year: D.number,
        is_default: D.boolean,
      })
    )
  ),
  past_subscription_charges: D.array(SourceSubscriptionChargeDecoder),
  upcomming_subscription_charges: D.array(SourceSubscriptionChargeDecoder),
  cancellation_tx: D.nullable(SourceSubscriptionChargeTransactionDecoder),
});
export type SourceMembershipType = D.TypeOf<typeof SourceMembershipDecoder>;
export const MembershipDecoder = D.partial({
  id: D.string,
  type: D.string,
  dateAccepted: D.string,
  dateExpires: D.nullable(D.string),
  dateCancelled: D.nullable(D.string),
  cancellationReasonId: D.nullable(D.string),
  cancellationReasonLabel: D.nullable(D.string),
  dayOfMonthCharge: D.string,
  priceMonthly: D.number,
  role: D.literal('gifter', 'standard'),
  nbUpcomingSubscriptionCharges: D.number,
  nbPastSubscriptionCharges: D.number,
  nbRequiredCharges: D.number,
  completedContract: D.boolean,
  isSelfRedeemedGift: D.boolean, // You got it by redeeming a code yourself
  isGift: D.boolean, // You received it as a gift
  giftFromFirstName: D.nullable(D.string), // The name of the person that gifted it to you
  giftFromLastName: D.nullable(D.string),
  paymentProfiles: D.nullable(
    D.array(
      D.partial({
        id: StringOrNumberDecoder,
        profile_name: D.string,
        abbreviation: D.string,
        last_four: D.string,
        expiration_month: D.number,
        expiration_year: D.number,
        is_default: D.boolean,
      })
    )
  ),
  pastSubscriptionCharges: D.array(SubscriptionChargeDecoder),
  upcomingSubscriptionCharges: D.array(SubscriptionChargeDecoder),
  terminationCharge: D.nullable(SubscriptionChargeTransactionDecoder),
  //
  cancelled: D.boolean,
  cancelledNaturally: D.boolean,
});
export type MembershipType = D.TypeOf<typeof MembershipDecoder>;

/**
 * Gifted Memberships
 */
export const SourceGiftedMembershipDecoder = D.partial({
  id: StringOrNumberDecoder,
  type: D.string,
  dt_accepted: D.string,
  dt_expires: D.nullable(D.string),
  dt_cancel: D.nullable(D.string),
  cancellation_reason_id: D.nullable(D.string),
  cancellation_reason_label: D.nullable(D.string),
  day_of_month_charge: D.string,
  price: D.number,
  billing_type: D.literal('Anual', 'Monthly billing'),
  role: D.literal('gifter', 'standard'),
  num_upcomming_subscription_charges: D.number,
  num_past_subscription_charges: D.number,
  num_required_charges: D.number,
  completed_contract: D.boolean,
  is_redeemed: D.boolean,
  gift_to_fname: D.nullable(D.string),
  gift_to_lname: D.nullable(D.string),
  payment_profile: D.nullable(
    D.array(
      D.partial({
        id: StringOrNumberDecoder,
        profile_name: D.string,
        abbreviation: D.string,
        last_four: D.string,
        expiration_month: D.number,
        expiration_year: D.number,
        is_default: D.boolean,
      })
    )
  ),
  past_subscription_charges: D.array(SourceSubscriptionChargeDecoder),
  upcomming_subscription_charges: D.array(SourceSubscriptionChargeDecoder),
  cancellation_tx: D.nullable(SourceSubscriptionChargeTransactionDecoder),
});
export type SourceGiftedMembershipType = D.TypeOf<typeof SourceGiftedMembershipDecoder>;
export const GiftedMembershipDecoder = D.partial({
  id: D.string,
  type: D.string,
  dateAccepted: D.string,
  dateExpires: D.nullable(D.string),
  dateCancelled: D.nullable(D.string),
  cancellationReasonId: D.nullable(D.string),
  cancellationReasonLabel: D.nullable(D.string),
  dayOfMonthCharge: D.string,
  priceMonthly: D.number,
  billingType: D.literal('Anual', 'Monthly billing'),
  role: D.literal('gifter', 'standard'),
  nbUpcomingSubscriptionCharges: D.number,
  nbPastSubscriptionCharges: D.number,
  nbRequiredCharges: D.number,
  completedContract: D.boolean,
  isRedeemed: D.boolean,
  giftToFirstName: D.nullable(D.string),
  giftToLastName: D.nullable(D.string),
  paymentProfiles: D.nullable(
    D.array(
      D.partial({
        id: StringOrNumberDecoder,
        profile_name: D.string,
        abbreviation: D.string,
        last_four: D.string,
        expiration_month: D.number,
        expiration_year: D.number,
        is_default: D.boolean,
      })
    )
  ),
  pastSubscriptionCharges: D.array(SubscriptionChargeDecoder),
  upcomingSubscriptionCharges: D.array(SubscriptionChargeDecoder),
  terminationCharge: D.nullable(SubscriptionChargeTransactionDecoder),
  //
  cancelled: D.boolean,
  cancelledNaturally: D.boolean,
});
export type GiftedMembershipType = D.TypeOf<typeof GiftedMembershipDecoder>;
